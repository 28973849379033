import PageWrapper from "components/utility/PageWrapper";

import "firebase/firestore";

import { Link } from "react-router-dom";
import { getGCInviteCode } from "models/growthCircleSession";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { ImEnter } from "react-icons/im";
import { Suspense, lazy } from "react";

const MyGCSession = lazy(() => import("./MyGCSession"));

export default function MyCircles() {
  const { growthCircleSession } = useGrowthCircleContext();
  const { profile } = useAuthContext();
  const gcID = growthCircleSession?.id ?? "";
  const code = getGCInviteCode(growthCircleSession);
  const rejoinLink = !profile?.pageStep
    ? `/all-circles/welcome/${gcID}`
    : `${profile.pageStep}`;

  return (
    <PageWrapper pageType={"groundRules"}>
      <div className="w-full mb-[100px] xl:mb-0 ">
        <Suspense
          fallback={
            <div
              className={`bg-white flex flex-col justify-center items-center gap-2 shadow-md  w-full rounded-md max-w-screen-2xl p-10 mx-auto px-2 sm:px-6 lg:px-8 mb-60  mt-[2%]`}
            >
              Loading...
            </div>
          }
        >
          <MyGCSession />
        </Suspense>

        {profile &&
          profile.pageStep !== null &&
          profile.pageStep !== "" &&
          code !== "" && (
            <div
              className={`bg-white flex flex-col justify-center items-center gap-2 shadow-md  w-full rounded-md max-w-screen-2xl p-10 mx-auto px-2 sm:px-6 lg:px-8 mb-60  mt-[4%]`}
            >
              <h3 className="text-center break-words">
                Your current active session with{" "}
                <strong>
                  {growthCircleSession && growthCircleSession.name}
                </strong>
              </h3>
              <p className="text-2xl">
                {"Invite Code : "}
                <span className="font-medium">{code}</span>
              </p>
              <Link
                className="shadow-lg transition hover:bg-red-500 hover:border-red-400 duration-1000 text-white text-center rounded-full mt-5 uppercase bg-red-400 px-8 py-5 flex gap-5 border-4 border-red-500"
                to={rejoinLink}
              >
                <ImEnter size={20} /> <span>Re-Join</span>
              </Link>
            </div>
          )}
      </div>
    </PageWrapper>
  );
}
