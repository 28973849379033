import classes from "./Admin.module.css";
import { useState, useEffect } from "react";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";

import { Profile } from "interface/ProfileInterface";

import SimpleLoader from "components/Loaders/SimpleLoader";
import useUsers from "hooks/useUsers";
import usePromise from "hooks/utility/usePromise";
import { inputClass } from "strings/InputClassStrings";
import UserItem from "./UserItem";
import { useFirestore } from "hooks/useFirestore";
import UserListLoader from "components/Loaders/UserListLoader";

// const CARDS_PER_PAGE:number = 12;

export default function Users() {
  const [Users, setUsers] = useState<Profile[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<Profile[]>([]);
  const { getAllUsers } = useUsers();
  const [localUsers, setLocalUsers] = useState<Profile[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isLoading, resolve } = usePromise();
  const { updateDocument } = useFirestore("users");
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const paginationItems: JSX.Element[] = [];

    const createPaginationItem = (i: number) => (
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{
          backgroundColor:
            currentPage === i ? "var(--icon-colour-2)" : "#EB8181",
        }}
        className={`my-5 mx-1 px-3 py-1 rounded-md `}
      >
        {i}
      </button>
    );

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    } else {
      paginationItems.push(createPaginationItem(1));

      if (currentPage > 3) {
        paginationItems.push(
          <span key="ellipsis1" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(createPaginationItem(i));
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(
          <span key="ellipsis2" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      paginationItems.push(createPaginationItem(totalPages));
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationItems}
        <button
          onClick={() => paginate(currentPage + 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query);

      return matchesSearchQuery;
    });
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when search query or filter changes
  };

  const fetchUsers = async () => {
    try {
      const _users = await getAllUsers();

      if (_users.length > 0) {
        const _filteredUsers = _users
          .filter((user) => user.displayNameLowerCase && user.email)
          .sort((a, b) =>
            a.displayNameLowerCase.localeCompare(b.displayNameLowerCase)
          );
        setUsers(_filteredUsers);
        setLocalUsers(_filteredUsers);
      } else {
        setUsers([]);
        setLocalUsers([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    resolve(() => fetchUsers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filtered = localUsers.filter((user) => {
      const matchesSearchQuery =
        user.displayNameLowerCase.includes(searchQuery) ||
        user.email.toLowerCase().includes(searchQuery);

      return matchesSearchQuery;
    });

    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
    // eslint-disable-next-line
  }, [searchQuery, localUsers]);

  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main}`}>
          <div className={classes.title}>
            <h4>Users</h4>
          </div>

          {!isLoading && Users && Users.length > 0 ? (
            <>
              <div className="relative gap-2 flex items-center justify-end w-1/4 mt-2">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className={inputClass}
                  style={{ minWidth: "200px" }}
                />
              </div>
              <p className="text-standard  pt-4">
                {searchQuery === "" ? (
                  <span className="font-semibold ">
                    Total of {filteredUsers.length} users{" "}
                  </span>
                ) : (
                  <span className="font-semibold">
                    Total of {filteredUsers.length} user(s) found for search{" "}
                    <i>{searchQuery}</i>
                  </span>
                )}
              </p>
              <div className="flex gap-2 flex-wrap my-4">
                {currentItems.map((user, index) => (
                  <UserItem
                    key={index}
                    user={user}
                    updateDocument={updateDocument}
                  />
                ))}
              </div>
              {filteredUsers.length > 0 ? (
                <>
                  {" "}
                  <div>{renderPaginationButtons()}</div>
                </>
              ) : (
                <>
                  {!isLoading ? (
                    <div className="min-h-[10vh] flex justify-center items-center">
                      <p>No records available.</p>
                    </div>
                  ) : (
                    <div className="w-full mt-2 min-[30vh] flex justify-center items-center">
                      {" "}
                      <SimpleLoader />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <UserListLoader quantity={12} withProfile={true} />
          )}
        </div>
      </div>
    </div>
  );
}
