import { useEffect, useState } from "react";

import PageWrapper from "components/utility/PageWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";

import { useGCSession } from "hooks/useGCSession";
import { useDocument } from "hooks/useDocument";
// import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

import { checkExpired, updateGCSession } from "models/growthCircleSession";

import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useIceBreaker } from "hooks/useIceBreaker";
import LoadingWidget from "components/utility/LoadingWidget";
import { INFO_PANEL_ROUTE } from "strings/Routes";
import { useGrowthCircles } from "hooks/useGrowthCircles";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { Modal } from "flowbite-react";
import classes from "pages/AllCircles/Admin/Admin.module.css";

const Welcome = () => {
  const { user, profile } = useAuthContext();
  const { getGCDetailsById } = useGrowthCircles();
  const [show, setShow] = useState(false);
  const { id } = useParams();

  //const url = user ? ENTER_CODE_ROUTE : INFO_PANEL_ROUTE.replace("id", id!);

  const { updateDocument } = useFirestore("users");
  const { document: growthCircleSession } = useDocument("GrowthCircles", id);

  const { updateGCwithID } = useGCSession();

  // const { growthCircleSession } = useGrowthCircleContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { getIceBreakersByOrgId } = useIceBreaker();
  const navigate = useNavigate();

  const updateSteps = async () => {
    if (id) {
      if (user && profile) {
        const updateGC = {
          growthCircle: id,
          pageStep: window.location.pathname,
          isEmailSent: false,
          pageNumber: 1,
        };

        await updateDocument(profile.uid, updateGC);
        //  check if user email is included to facil email list
        // checkEmail(user.email ? user.email : "", profile.isFacil, profile.uid);
        await updateGCwithID(id);
        await GetParticipants(id);
        const countdown = setTimeout(() => {
          console.debug("Not part of any organisation");
          setShow(true);
        }, 5000);

        return () => clearTimeout(countdown);
      } else {
        navigate(INFO_PANEL_ROUTE.replace("id", id));
      }
    } else {
      navigate(`/404`);
    }
  };

  const GetParticipants = async (gcID: string | undefined) => {
    if (
      gcID &&
      growthCircleSession &&
      checkExpired(growthCircleSession, growthCircleSession.sessionExpire)
    ) {
      console.debug(
        `Expired is : ${checkExpired(
          growthCircleSession,
          growthCircleSession.sessionExpire
        )}`
      );
    }
  };

  const shuffleDice = async (array: string[]): Promise<string[]> => {
    let shuffledArray: string[] = [];

    // Shuffle the array using Fisher-Yates algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    // Take the first 9 elements of the shuffled array
    shuffledArray = array.slice(0, array.length);

    return shuffledArray;
  };

  useEffect(() => {
    updateSteps();
    // eslint-disable-next-line
  }, [profile, selectedOrganisation]);

  useEffect(() => {
    if (!id) return;
    if (!selectedOrganisation) return;
    if (!growthCircleSession) return;
    localStorage.setItem("TriggerRead", "false");
    localStorage.setItem("SharingRead", "false");
    localStorage.setItem("ChooseRoleRead", "false");
    localStorage.setItem("preGC", id);

    userIsAuthProcess();

    // eslint-disable-next-line
  }, [selectedOrganisation, id]);

  const userIsAuthProcess = async () => {
    if (selectedOrganisation && growthCircleSession) {
      const { iceBreakerType } = selectedOrganisation;

      if (iceBreakerType && iceBreakerType === "dice-custom") {
        const iceBreakers = await getIceBreakersByOrgId(
          selectedOrganisation.id
        );
        const data = iceBreakers.filter(
          (icebreaker) => icebreaker.type === iceBreakerType
        );
        const [_data] = data;
        if (_data && _data.images.length > 0) {
          const dicesImages = await shuffleDice(_data.images);
          await updateGCSession(growthCircleSession.uid, { dicesImages });
          navigate("/growthcircle");
        } else {
          await updateGCSession(growthCircleSession.uid, { dicesImages: [] });
          navigate("/growthcircle");
        }
      } else {
        await updateGCSession(growthCircleSession.uid, { dicesImages: [] });
        navigate("/growthcircle");
      }
    } else {
      if (id && selectedOrganisation) {
        const _growthCircleSession = (await getGCDetailsById(
          id
        )) as GrowthCircleSession;

        const { iceBreakerType } = selectedOrganisation;

        if (iceBreakerType && iceBreakerType === "dice-custom") {
          const iceBreakers = await getIceBreakersByOrgId(
            selectedOrganisation.id
          );
          const data = iceBreakers.filter(
            (icebreaker) => icebreaker.type === iceBreakerType
          );

          const [_data] = data;
          if (_data && _data.images.length > 0) {
            const dicesImages = await shuffleDice(_data.images);
            await updateGCSession(_growthCircleSession.uid, { dicesImages });
            navigate("/growthcircle");
          } else {
            await updateGCSession(_growthCircleSession.uid, {
              dicesImages: [],
            });
            navigate("/growthcircle");
          }
        } else {
          navigate("/growthcircle");
        }
      }
    }
  };

  const noOrgHandler = () => {
    navigate(`/`);
  };

  return (
    <>
      <Modal show={show} size={"lg"} className="z-[100000000]">
        <Modal.Body className="text-standard">
          <div
            className={`${classes["container"]} text-center flex flex-col gap-4 justify-center items-center py-5`}
          >
            You are not subscribed or invited to this organization. Please reach
            out to your facilitator or administrator to have you added to the
            user list.
            <button onClick={noOrgHandler} className={`${classes["button"]}`}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <PageWrapper>
        <LoadingWidget />
      </PageWrapper>
    </>
  );
};

export default Welcome;
