import { useEffect, useState } from "react";
import classes from "./IntroRating.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import useParticipants from "hooks/useParticipants";
import EmptySpace from "components/utility/EmptySpace";
import { DRAG_RATING_KEYS, useDragRating } from "../../../hooks/useDragRating";
import { LocalStorageKeys } from "App";
import { useComponentDidUpdate } from "hooks/useComponentDidUpdate";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import SliderCustom from "components/SliderCustom/SliderCustom";
import React from "react";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

/**
 * Page that includes 4 drag sliders to rate their checkIns
 */
const IntroRatingComponent = ({ backAndNextHandler }: ComponentProps) => {
  const { profile } = useAuthContext();
  const userStorageKey = profile!.id + "_";
  const { updateGCSessionCheckIn } = useParticipants();
  const { selectedOrganisation } = useOrganisationContext();

  const dragRatingKey = userStorageKey + LocalStorageKeys.INTRO_RATING;
  const {
    dragValues: introDragValues,
    setDragValues: setIntroDragValues,
    anyUntouched: anyIntroUntouched,
    dragError: showIntroDragError,
    setDragError: setShowIntroDragError,
    setKeyToTouched,
    removeDragRatingsFromStorage,
  } = useDragRating(dragRatingKey);
  const [individually, setIndividually] = useState(
    introDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value
  );
  const [interpersonally, setInterpersonally] = useState(
    introDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value
  );
  const [socially, setSocially] = useState(
    introDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value
  );
  const [overall, setOverall] = useState(
    introDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value
  );

  const submitHandler = () => {
    if (anyIntroUntouched()) {
      setShowIntroDragError(true);
      console.log(showIntroDragError);
    } else {
      const data = {} as CheckInCheckOut;
      data[DRAG_RATING_KEYS.INDIVIDUALLY.KEY] = individually;
      data[DRAG_RATING_KEYS.INTERPERSONALLY.KEY] = interpersonally;
      data[DRAG_RATING_KEYS.SOCIALLY.KEY] = socially;
      data[DRAG_RATING_KEYS.OVERALL.KEY] = overall;
      updateGCSessionCheckIn(data);
      removeDragRatingsFromStorage();
    }
  };

  //Set touch to true for drag ratings
  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INDIVIDUALLY.KEY);
  }, [individually]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.INTERPERSONALLY.KEY);
  }, [interpersonally]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.SOCIALLY.KEY);
  }, [socially]);

  useComponentDidUpdate(() => {
    setKeyToTouched(DRAG_RATING_KEYS.OVERALL.KEY);
  }, [overall]);

  useEffect(() => {
    const newIntroDragValues = { ...introDragValues };
    newIntroDragValues[DRAG_RATING_KEYS.INDIVIDUALLY.KEY].value = individually;
    newIntroDragValues[DRAG_RATING_KEYS.INTERPERSONALLY.KEY].value =
      interpersonally;
    newIntroDragValues[DRAG_RATING_KEYS.SOCIALLY.KEY].value = socially;
    newIntroDragValues[DRAG_RATING_KEYS.OVERALL.KEY].value = overall;
    setIntroDragValues(newIntroDragValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individually, interpersonally, socially, overall]);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  return (
    <React.Fragment>
      <EmptySpace height={"1rem"} />
      <div className={classes.description}>
        {selectedOrganisation?.name === "NTU Career & Attachment Office"
          ? "On a scale of 1-10, how much clarity do you have in the following areas, to support your career journey?"
          : "How have you been feeling the past week in the following areas?"}
      </div>
      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Industry insights"
                : "Individually"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Personal well-being"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={individually}
                setValue={setIndividually}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Job roles"
                : "Interpersonally"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Family, Close relationships"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={interpersonally}
                setValue={setInterpersonally}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Career Paths & Progression"
                : "Socially"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "Work, school, friendships"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={socially}
                setValue={setSocially}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[320px] md:w-[400px] mx-auto mt-5 ">
        <div className="w-full mx-auto">
          <div className={`${classes.box} shadow-md`}>
            <div className={classes.header}>
              {" "}
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? "Skills Relevant To Industry"
                : "Overall"}
            </div>
            <div className={classes["header-description"]}>
              {selectedOrganisation?.name === "NTU Career & Attachment Office"
                ? ""
                : "General sense of well-being"}
            </div>
            <div className="flex justify-center px-2">
              <SliderCustom
                maxValue={selectedOrganisation?.maxRating ?? 10}
                withBackground={true}
                value={overall}
                setValue={setOverall}
              />
            </div>
          </div>
        </div>
      </div>

      <EmptySpace height={"40px"} />
      <BackAndNext
        {...backAndNextHandler}
        nextCallback={(e) => {
          submitHandler();
          backAndNextHandler.nextCallback(e);
        }}
        nextAllowed={!anyIntroUntouched() && backAndNextHandler.nextAllowed}
      />
    </React.Fragment>
  );
};

export default IntroRatingComponent;
