import { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";

interface DashboardFilterInputsProps {
  label: string;
  type: "select" | "text" | "date";
  options?: string[];
  onChange?: Function;
  initialSelected?: string;
}

/**
 * Dashboard filter inputs component
 *
 * @param {object} props The props of the component
 * @param {string} props.label The label of the input
 * @param {string} props.type The type of the input
 * @param {string[]} props.options The options to select for the input
 * @param {Function} props.onChange The function to call when the input changes
 * @param {string} props.initialSelected The initial selected value
 * @returns {JSX.Element} The dashboard filter inputs component
 */
const DashboardFilterInputs = ({
  label,
  type,
  options,
  onChange,
  initialSelected,
}: DashboardFilterInputsProps) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(initialSelected ?? "");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  useEffect(() => {
    if (onChange) {
      if (type === "select") {
        onChange(selected);
      } else if (type === "date" && from !== "" && to !== "") {
        onChange(`${from} to ${to}`);
      }
    }

    // eslint-disable-next-line
  }, [selected, from, to]);

  useEffect(() => {
    if (initialSelected) {
      setSelected(initialSelected);
    }
  }, [initialSelected]);

  return (
    <>
      {type === "date" && (
        <>
          <div
            className={`bg-default w-auto mt-8 p-2 flex items-center justify-between  ${
              show
                ? "border rounded-br-none rounded-bl-none rounded-tr-2xl rounded-tl-2xl px-5 "
                : "rounded-full"
            }`}
            style={{ borderColor: show ? "var(--icon-colour-0)" : "" }}
            onClick={() => setShow(!show)}
          >
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center">
                <p
                  className="font-semibold text-lg select-none px-2"
                  style={{ color: "var(--icon-colour-0)" }}
                >
                  {label}
                </p>
                <div>
                  <BiSolidDownArrow
                    style={{ color: "var(--icon-colour-0)" }}
                    size={20}
                  />
                </div>
              </div>
              <div>
                <p className="font-semibold px-2">
                  {" "}
                  {from && to && `${from} to ${to}`}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`bg-default border border-t-0 rounded-md ${
              show ? "" : "hidden"
            } flex flex-col justify-center items-center p-5 gap-2`}
            style={{ borderColor: "var(--icon-colour-0)" }}
          >
            <input type="date" onChange={(e) => setFrom(e.target.value)} />
            <p className="font-bold text-lg">To</p>
            <input type="date" onChange={(e) => setTo(e.target.value)} />
          </div>
        </>
      )}
      {type === "select" && (
        <>
          <div
            className={`bg-default w-auto mt-8 p-2 flex items-center justify-between  ${
              show
                ? "border rounded-br-none rounded-bl-none rounded-tr-2xl rounded-tl-2xl px-5 "
                : "rounded-full"
            }`}
            style={{ borderColor: show ? "var(--icon-colour-0)" : "" }}
            onClick={() => setShow(!show)}
          >
            {" "}
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center">
                <p
                  className="font-semibold text-lg select-none px-2"
                  style={{ color: "var(--icon-colour-0)" }}
                >
                  {label}
                </p>
                <div>
                  <BiSolidDownArrow
                    className="shrink-0 cursor-pointer"
                    style={{ color: "var(--icon-colour-0)" }}
                    size={20}
                  />
                </div>
              </div>
              <div>
                <p className="font-semibold px-2">{selected}</p>
              </div>
            </div>
          </div>
          <div
            className={`bg-default max-h-64 overflow-y-scroll border border-t-0 ${
              show ? "" : "hidden rounded-md"
            }`}
            style={{ borderColor: "var(--icon-colour-0)" }}
          >
            {options &&
              options.map((option, index) => (
                <p
                  style={{ color: "var(--icon-colour-0)" }}
                  className="font-semibold text-lg select-none p-2 hover:bg-slate-300"
                  key={index}
                  onClick={() => {
                    setSelected(option);
                    setShow(!show);
                  }}
                >
                  {option}
                </p>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default DashboardFilterInputs;
