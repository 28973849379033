import { useCallback, useState } from "react";
import DiceImages from "../components/Dice/DiceImages";
import { useAuthContext } from "./useAuthContext";
import { DRAG_RATING_KEYS } from "./useDragRating";
import { useFirestore } from "./useFirestore";
import { CheckInCheckOut, Participant } from "interface/ParticipantInterface";
import { FeedbackForm } from "interface/FeedbackFormInterface";
import { projectFirestore } from "../firebase/config";
import "moment-timezone";
import "firebase/firestore";
import {
  deleteParticipants,
  resetDice,
  shuffleDice,
} from "models/growthCircleSession";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";

export const useGCSession = () => {
  const { updateDocument } = useFirestore("GrowthCircles");
  const { updateDocument: updateProfile } = useFirestore("users");
  const { profile } = useAuthContext();
  const growthCirclesId = profile?.growthCircle ? profile.growthCircle : "";
  // const { document } = useDocument("GrowthCircles", growthCirclesId);
  const [isPending] = useState(false);
  const [error] = useState(false);
  const [errorMessage] = useState(null);

  /**
   * It takes two arrays of objects, compares them, and returns an array of objects that are not in
   * the second array
   * @param sessionList - This is the list of sessions that you get from the Monday.com API.
   * @param sessionFirebase - is the list of sessions from firebase
   */
  const syncGBsessions = useCallback(
    async (sessionList: any[], sessionFirebase: any[]) => {
      var myArrayFiltered = sessionList.filter((object1) => {
        return !sessionFirebase.some((object2) => {
          return object1.name === object2.name;
        });
      });

      if (myArrayFiltered.length > 0) {
        myArrayFiltered.map((session) => {
          // return insertNewGB(session);
          //TODO: temporary disabled
          return session;
        });
      } else {
        // console.log("nothing to sync");
      }
    },
    []
  );

  interface GCSession {
    id: string;
    dices: number[];
  }
  /**
   * It takes a document object as an argument, and returns an array of 9 shuffled dice images
   * @param document - the document that is being passed in from the database
   * @returns the selection array.
   */
  const createDices = async (document: GCSession) => {
    if (!document || document.dices.length > 0) {
      return;
    }
    const shuffledDice: number[] = shuffleDice(DiceImages);
    let insertShuffledDices = { dices: shuffledDice };
    await updateDocument(document.id, insertShuffledDices);
  };

  /**
   * It takes in a newData object, and then calls the addDocument function, which is imported from the
   * firebase.js file
   * @param newData - This is the data that you want to add to the database.
   */

  // const insertNewGB = async (newData: any) => {
  //   await addDocument(newData);
  // };

  /**
   * It takes an array of numbers, adds them all together, divides the total by the number of items
   * in the array, and returns the result
   * @param array - The array of numbers to be averaged.
   * @returns The average of the numbers in the array.
   */
  const calculateAverage = (array: number[]) => {
    var total = 0;
    var count = 0;

    array.forEach(function (item, index) {
      total += item;
      count++;
    });

    return total / count;
  };

  /**
   * It takes in a document and a profile and returns an array of objects with the average of each
   * check in category.
   * @param participants list of all participants
   * @returns An array of objects with the name of the category and the average of the category.
   */
  const calculateAverageCheckIn = (participants: Participant[]) => {
    if (!participants) return;
    let allCheckIn = participants.filter((user) => {
      return user.checkIn.individually >= 0;
    });

    let individually: number[] = [];
    let Socially: number[] = [];
    let Interpersonally: number[] = [];
    let Overall: number[] = [];

    allCheckIn.map((user) => {
      individually.push(user.checkIn[DRAG_RATING_KEYS.INDIVIDUALLY.KEY]);
      Socially.push(user.checkIn[DRAG_RATING_KEYS.SOCIALLY.KEY]);
      Interpersonally.push(user.checkIn[DRAG_RATING_KEYS.INTERPERSONALLY.KEY]);
      Overall.push(user.checkIn[DRAG_RATING_KEYS.OVERALL.KEY]);
      return true;
    });
    // console.log(individually);
    // console.log(Interpersonally);
    // console.log(Socially);
    // console.log(Overall);
    // let newAverage = {};
    // newAverage[DRAG_RATING_KEYS.INDIVIDUALLY.KEY] = calculateAverage(individually);
    // newAverage[DRAG_RATING_KEYS.INTERPERSONALLY.KEY] = calculateAverage(Interpersonally);
    // newAverage[DRAG_RATING_KEYS.SOCIALLY.KEY] = calculateAverage(Socially);
    // newAverage[DRAG_RATING_KEYS.OVERALL.KEY] = calculateAverage(Overall);
    const newAverage: CheckInCheckOut = {
      individually: calculateAverage(individually),
      interpersonally: calculateAverage(Interpersonally),
      socially: calculateAverage(Socially),
      overall: calculateAverage(Overall),
    };

    return newAverage;
  };

  /**
   * Calculate the average of the specified check-in categories for a list of participants.
   * @param participants List of participants
   * @param checkInKeys Array of check-in keys to calculate averages for
   * @returns An object with the average of each specified category
   */
  const calculateORSAverageCheckIn = (
    participants: Participant[],
    checkInKeys: string[]
  ) => {
    if (
      !participants ||
      !Array.isArray(checkInKeys) ||
      checkInKeys.length === 0
    ) {
      return {};
    }

    const averages = {};

    // Initialize averages object with keys from checkInKeys array
    checkInKeys.forEach((key) => {
      averages[key] = [];
    });

    participants.forEach((user) => {
      checkInKeys.forEach((key) => {
        const value = user.checkIn[key];
        if (!isNaN(value)) {
          averages[key].push(value);
        }
      });
    });

    // Calculate the average for each key
    checkInKeys.forEach((key) => {
      averages[key] = calculateAverage(averages[key]);
    });

    return averages;
  };

  /**
   * @param averages: CheckIn - takes in CheckIn and checks each section for NaN values
   */
  const checkAllRatingAveragesNotNan = (averages: CheckInCheckOut) => {
    if (averages) {
      const allNotNaN = Object.keys(averages).reduce(
        (previous, currValue, currIndex) => {
          return !!averages[currValue] && previous;
        },
        true
      );
      return allNotNaN;
    }

    return false;
  };

  /**
   * Check if all values in an object are not NaN.
   * @param obj - The object to check.
   * @returns True if all values are not NaN, false otherwise.
   */
  const checkAllValuesNotNaN = (obj: Record<string, number>) => {
    if (obj) {
      const allNotNaN = Object.values(obj).every(
        (value) => typeof value === "number" && !isNaN(value)
      );
      return allNotNaN;
    }
    return false;
  };

  /**
   * Takes in an array of FeedbackForms and transforms it into an array of average check ins after reflection
   * @param feedbackForm: FeedbackForm[]
   */
  const calculateReflectionRatingAverages = (feedbackForms: FeedbackForm[]) => {
    const allReflectionCheckIns: CheckInCheckOut[] = feedbackForms
      .map((feedbackForm, index) => {
        return feedbackForm.refPersonalDragValues;
      })
      .filter((checkIn): checkIn is CheckInCheckOut => !!checkIn);

    const reflectionCheckInAverages: CheckInCheckOut =
      allReflectionCheckIns.reduce<CheckInCheckOut>(
        (previous, current, index) => {
          if (Object.keys(previous).length === 0) {
            return current;
          } else {
            Object.keys(previous).forEach((checkInKey) => {
              previous[checkInKey] = previous[checkInKey] + current[checkInKey];
            });
            return previous;
          }
        },
        {
          individually: 0,
          interpersonally: 0,
          socially: 0,
          overall: 0,
        }
      );

    Object.keys(reflectionCheckInAverages).forEach((checkIn) => {
      reflectionCheckInAverages[checkIn] =
        reflectionCheckInAverages[checkIn] / allReflectionCheckIns.length;
    });

    // console.log(reflectionCheckInAverages);
    return reflectionCheckInAverages;
  };

  /**
   * Groups Feedback Forms according to reflection check in key provided
   * @param key: string
   * @param feedbackForm: FeedbackForm[]
   */
  const getGroupedFeedbackFormsByCheckInKey = (
    key: string,
    feedbackForms: FeedbackForm[]
  ) => {
    //Set otherValues to contain the other handles needed for the bar
    let result: FeedbackForm[][] = [];
    let groupArray: FeedbackForm[] = [];

    const feedbackFormsClone: FeedbackForm[] = [...feedbackForms]
      .filter((feedbackForm) => feedbackForm.refPersonalDragValues)
      .sort(
        (feedback1, feedback2) =>
          feedback1.refPersonalDragValues![DRAG_RATING_KEYS[key].KEY] -
          feedback2.refPersonalDragValues![DRAG_RATING_KEYS[key].KEY]
      );
    if (!profile?.isFacil) {
      //Get participant that is user
      const userFeedback: FeedbackForm | undefined = feedbackFormsClone.find(
        (feedback) => feedback.userId === profile?.id
      );
      groupArray = [];
      if (userFeedback) {
        groupArray.push(userFeedback);
        result.push(groupArray);
        return result;
      }
    } else {
      for (let i = 0; i < feedbackFormsClone.length; i++) {
        const currCheckIn =
          feedbackFormsClone[i].refPersonalDragValues![
            DRAG_RATING_KEYS[key].KEY
          ];
        const prevCheckIn =
          feedbackFormsClone[i - 1]?.refPersonalDragValues![
            DRAG_RATING_KEYS[key].KEY
          ];
        if (currCheckIn !== prevCheckIn) {
          groupArray = [];
          result.push(groupArray);
        }
        groupArray.push(feedbackFormsClone[i]);
      }

      // console.log(result);
      return result;
    }
  };

  /**
   * Sets isOngoing property for current GC session to false
   */
  //TODO: this is where the GC is set to isOngoing to false
  const endGCSession = async () => {
    await updateDocument(growthCirclesId, {
      isOngoing: true, //false
    });
  };

  /**
   * It takes a string, splits it into an array of words, maps over the array to get the first letter
   * of each word, joins the array of first letters into a string, and returns the string
   * @param str - The string to be split.
   * @returns The first letter of each word in the string.
   */
  const getFirstLetters = (str: string) => {
    const firstLetters =
      str ??
      "Bean"
        .split(" ")
        .map((word) => word[0])
        .join("");

    return firstLetters;
  };

  type NextPage =
    | "dice"
    | "intro-rating"
    | "setting-intentions"
    | "choose-roles"
    | "choose-topic"
    | "choose-pathway"
    | "sharing-panel"
    | "end-rating"
    | "end-rating-average"
    | "reflection-rating"
    | "end-of-session"
    | "choose-activity"
    | "add-activity"
    | "allow-skip-feedback";

  const facilAllowNextPage = async (page: NextPage) => {
    let object = {};
    switch (page) {
      case "dice":
        object = { allowDice: true };
        break;
      case "intro-rating":
        object = { allowIntroRating: true };
        break;
      case "setting-intentions":
        object = { allowSettingIntentions: true };
        break;
      case "choose-roles":
        object = { allowChooseRoles: true };
        break;
      case "choose-topic":
        object = { allowChooseTopic: true };
        break;
      case "choose-pathway":
        object = { allowChoosePathway: true };
        break;
      case "sharing-panel":
        object = { allowSharingPanel: true };
        break;
      case "end-rating":
        object = { allowEndRating: true };
        break;
      case "end-rating-average":
        object = { allowEndRatingAverage: true };
        break;
      case "reflection-rating":
        object = { allowReflectionRating: true };
        break;
      case "choose-activity":
        object = { allowChooseActivity: true };
        break;
      case "add-activity":
        object = { allowAddActivity: true };
        break;
      case "end-of-session":
        object = { allowEndOfSession: true };
        break;
      case "allow-skip-feedback":
        object = { allowSkipFeedBackForm: true };
        break;
    }
    await updateDocument(growthCirclesId, object);
  };
  /**
   * Updates the latest page number the facil is on
   * @param n New page number
   */
  const updateFacilPageNumber = async (n: number) => {
    await updateDocument(growthCirclesId, { facilPageNumber: n });
  };

  const updateGCwithID = async (id: string | undefined) => {
    let object = { uid: id };
    return await updateDocument(id, object);
  };

  /**
   * It takes in a growth circle id and an array of documents and updates the growth circle's
   * participant history with the documents
   * @param {string} id - the id of the growth circle
   * @param {any} documents - an array of objects that contain the data you want to store in the
   * database.
   */
  const refreshGCSession = async (id: string) => {
    deleteParticipants(id);
    await resetDice(id);
    await updateProfile(profile?.uid, { pageStep: null });
  };

  const safeRefreshGCSession = async (id: string) => {
    await deleteParticipants(id);
    await resetDice(id);
    await updateProfile(profile?.uid, { pageStep: null });
  };

  // create a function createShareAndSupportBackUp that will copy the the document from ShareAndSupport Collection and move it to ShareAndSupportBackup Collection and then delete the document from ShareAndSupport Collection the parameters are growthCIrcleID and userID
  const createShareAndSupportBackUp = async (
    growthCircle: string,
    userId: string
  ) => {
    try {
      const currentDate = new Date();

      // Get all documents to be backed up from the ShareAndSupport collection
      const ref = projectFirestore
        .collection("ShareAndSupport")
        .where("growthCircle", "==", growthCircle)
        .where("userId", "==", userId);
      const querySnapshot = await ref.get();

      if (!querySnapshot.empty) {
        // Move all documents to the ShareAndSupportBackup collection
        const batch = projectFirestore.batch();
        querySnapshot.forEach((doc) => {
          const backupDocRef = projectFirestore
            .collection("ShareAndSupportBackup")
            .doc(currentDate.toISOString().slice(0, 10));
          const docsRef = backupDocRef.collection("docs").doc(doc.id);
          const data = doc.data();
          batch.set(docsRef, data);
          batch.delete(doc.ref);
        });
        // Update the ShareAndSupportBackup document's dateUpdated field
        const backupDocRef = projectFirestore
          .collection("ShareAndSupportBackup")
          .doc(currentDate.toISOString().slice(0, 10));
        batch.set(backupDocRef, { dateUpdated: currentDate }, { merge: true });
        await batch.commit();
      } else {
        console.info("No documents found in ShareAndSupport collection");
      }
    } catch (error) {
      console.error("Error backing up documents:", error);
    }
  };

  const updateGCLocation = async (location: string, details: string) => {
    let data = { location: location, locationDetails: details };
    await updateDocument(growthCirclesId, data);
  };

  // for SUSS
  const _getSubCategory = useCallback((text: string | null) => {
    switch (text) {
      case "Growth Circle 1 (GC1)":
        return "GC SU1";
      case "Growth Circle 2 (GC2)":
        return "GC SU2";
      case "Growth Circle 3 (GC3)":
        return "GC SU3";
      case "Growth Circle 4 (GC4)":
        return "GC SU4";
      default:
        return "GC SU1";
    }
  }, []);

  const updateSelectedActivity = useCallback(
    (newActivity: string, votes: number) => {
      const updates: Partial<GrowthCircleSession> = {
        subCategory: _getSubCategory(newActivity),
        Activity: [newActivity, votes],
        facilActivity: newActivity,
      };
      return updateDocument(growthCirclesId, updates);
    },
    [growthCirclesId, updateDocument, _getSubCategory]
  );

  return {
    syncGBsessions,
    calculateAverageCheckIn,
    calculateReflectionRatingAverages,
    checkAllRatingAveragesNotNan,
    createDices,
    endGCSession,
    refreshGCSession,
    isPending,
    error,
    errorMessage,
    getFirstLetters,
    getGroupedFeedbackFormsByCheckInKey,
    facilAllowNextPage,
    updateFacilPageNumber,
    updateGCwithID,
    createShareAndSupportBackUp,
    updateGCLocation,
    updateSelectedActivity,
    safeRefreshGCSession,
    calculateORSAverageCheckIn,
    checkAllValuesNotNaN,
  };
};
