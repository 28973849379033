import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { DocumentData } from "firebase/firestore";
import { Button, Modal } from "flowbite-react";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSort } from "react-icons/fa";
import { inputClass } from "strings/InputClassStrings";
import ORSItem from "./ORSItem";
import ORSSortComponent from "./ORSSortComponent";

interface ORSProps {
  ors: DocumentData[];
}

const ORSSettings = ({ ors }: ORSProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const { AddNewORS, saveOrder, updateORSTitle } = useOrgORS();
  const { isLoading, resolve } = usePromise();
  const { isLoading: isSaving, resolve: saving } = usePromise();
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { isLoading: isUpdating, resolve: updating } = usePromise();
  const [orsTitle, setORSTitle] = useState<string>("");
  const [maxRating, setMaxRating] = useState<number>(
    selectedOrganisation?.maxRating ?? 10
  );

  const [checkboxState, setCheckboxState] = useState({
    setMaxRating: false,
    enableFlagRating: true,
  });

  const isSortHandler = () => {
    setSelectedIds([]);
    const initialSelectedIds = ors.map((question) => question.id);
    setSelectedIds(initialSelectedIds);

    setIsSorting(!isSorting);
  };

  useEffect(() => {
    if (!ors) return;
    if (!selectedOrganisation) return;
    if (selectedOrganisation.customORSTitle) {
      setORSTitle(selectedOrganisation.customORSTitle);
    } else {
      setORSTitle(
        "How have you been feeling the past week in the following areas?"
      );
    }
    if (selectedOrganisation.maxRating) {
      setMaxRating(selectedOrganisation.maxRating);
    } else {
      setMaxRating(10);
    }
    //eslint-disable-next-line
  }, [ors, selectedOrganisation]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    resolve(() => AddNewORS(title, subTitle)).then(() => {
      toast.success("New ORS was added successfully");
      setTitle("");
      setSubTitle("");
    });
  };

  const submitORSTitleHandler = (e: FormEvent) => {
    e.preventDefault();
    saving(() => updateORSTitle(orsTitle)).then(() => {
      updateSelectedOrganisation({ customORSTitle: orsTitle });
      toast.success("ORS custom title was saved successfully");
    });
  };

  const saveHandler = async () => {
    resolve(() => saveOrder(selectedIds)).then(() => {
      isSortHandler();
      toast.success("New order saved.");
    });
  };

  const handleCheckboxChange = async (checkboxName: string) => {
    const updatedCheckboxState = {
      ...checkboxState,
      [checkboxName]: !checkboxState[checkboxName],
    };
    updating(() =>
      updateSelectedOrganisation({
        [checkboxName]: updatedCheckboxState[checkboxName],
      })
    ).then(() => {
      setCheckboxState(updatedCheckboxState);
      toast.success("Settings was updated successfully.");
    });
  };

  const handleSaveMaxRating = async () => {
    if (maxRating <= 10 && maxRating >= 5) {
      try {
        resolve(() =>
          updateSelectedOrganisation({
            maxRating,
          })
        ).then(() => {
          setMaxRating(maxRating);
          toast.success("Max rating was saved successfully!");
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Invalid max rating value.");
    }
  };

  return (
    <>
      {" "}
      <Modal show={isSorting} size="7xl" popup onClose={isSortHandler}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center flex justify-center flex-col items-center">
            <ORSSortComponent
              order={selectedIds}
              setOrder={setSelectedIds}
              questions={ors}
            />
            {ors.length === selectedIds.length && (
              <Button disabled={isLoading} onClick={saveHandler}>
                {isLoading ? "Saving..." : "Save Order"}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="mb-[100px]">
        {selectedOrganisation && (
          <>
            <EmptySpace />
            <button
              className={`${classes["button"]}`}
              onClick={() => setIsTourOpen(true)}
            >
              Show Tutorial
            </button>
            <EmptySpace />
            <div className="flex items-center gap-4">
              <p className="text-xl my-4 text-slate-600">
                CheckIn / CheckOut for {/* Outcome Rating Score (ORS) for */}
                <strong>{selectedOrganisation.name}</strong>
              </p>
            </div>
            <hr />
            <div className="my-8 flex items-start gap-2 flex-col w-full md:w-1/2">
              <p className=" justify-start items-center gap-2 text-standard text-lg">
                You can customize the CheckIn/CheckOut instructions here.
              </p>
              <form
                onSubmit={submitORSTitleHandler}
                className="flex items-start gap-2 flex-col w-full"
              >
                <input
                  placeholder="Enter custom title here.."
                  value={orsTitle}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setORSTitle(e.target.value)
                  }
                  required
                  className={inputClass}
                />
                <button
                  className={`${classes["button"]}`}
                  disabled={isSaving}
                  type="submit"
                >
                  {isSaving ? "Submitting..." : "Save Custom Instruction"}
                </button>
              </form>
            </div>
            <hr />
            <div className="my-8">
              <p className="my-4 font-semibold">Advance Options</p>

              <div
                className={`${
                  isUpdating
                    ? "flex flex-col gap-2 opacity-[0.3]"
                    : "flex flex-col gap-2"
                }`}
              >
                <label className="cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    disabled={isUpdating}
                    checked={selectedOrganisation.setMaxRating ?? false}
                    onChange={() => handleCheckboxChange("setMaxRating")}
                    className="mx-4 mb-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor: selectedOrganisation.setMaxRating
                        ? "var(--icon-colour-0)"
                        : "",
                    }}
                  />
                  <span className="text-lg">
                    Enable custom max value ( default is 10 )
                  </span>
                </label>
                {selectedOrganisation.setMaxRating && (
                  <div className="w-[230px] mx-12 flex gap-2 my-4">
                    <input
                      disabled={isLoading}
                      type="number"
                      max={10}
                      min={5}
                      onChange={(e) => setMaxRating(parseInt(e.target.value))}
                      value={maxRating}
                    />
                    <button
                      disabled={isLoading}
                      className={`${classes["button"]}`}
                      onClick={handleSaveMaxRating}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                )}
                <label className="cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    disabled={isUpdating}
                    checked={selectedOrganisation.enableFlagRating ?? false}
                    onChange={() => handleCheckboxChange("enableFlagRating")}
                    className="mx-4 mb-2"
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor: selectedOrganisation.enableFlagRating
                        ? "var(--icon-colour-0)"
                        : "",
                    }}
                  />
                  <span className="text-lg">
                    Enable average outlier flagging for Check-In/Check-Out.
                  </span>
                </label>
              </div>
            </div>

            <hr />

            <form
              ors-settings-tour="create-ors"
              onSubmit={submitHandler}
              className="my-8 flex items-start gap-2 flex-col w-full md:w-1/2"
            >
              {" "}
              <p className=" mb-8 justify-start items-center gap-2 text-standard text-lg">
                Please input the title and sub title of the ORS below to create
                a new ORS entry. Ensure that there is no on-going session when
                you add or sort the ORS questions to avoid mis match data during
                session.
              </p>
              <input
                placeholder="Check-IN/Check-Out title"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
                required
                className={inputClass}
              />
              <div className="p-2 bg-white border border-gray-300  rounded-md w-full">
                <AutoResizeTextArea
                  className="border-none w-full placeholder:text-gray-300"
                  placeholder="Specify sub-title"
                  value={subTitle}
                  onChange={(value) => setSubTitle(value)}
                />
              </div>
              <button
                className={`${classes["button"]}`}
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? "Submitting..." : "Add Check-In/Check-Out"}
              </button>
            </form>
            <hr />
            <div
              ors-settings-tour="sort-ors"
              onClick={isSortHandler}
              className="cursor-pointer my-2 text-slate-600 flex gap-2 items-center w-1/4"
            >
              Sort
              <FaSort size={20} className="text-sky-700 cursor-pointer" />
            </div>
            <div className="py-4 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-2">
              {ors &&
                ors.map((item, index) => <ORSItem key={index} ors={item} />)}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ORSSettings;
