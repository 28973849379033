import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";

import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "strings/Routes";
import GrowthCollectiveLogo from "assets/NG/Logos/GrowthCollective.webp";
import NationalGalleryLogo from "assets/NG/Logos/NationalGallery.webp";
import NGFooter from "components/Footers/NGFooter";
import SUSSLogo from "assets/SUSS/SUSS.png";
import NTULogo from "assets/NTU/BuddyUp.png";
import { Link } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { useFirestore } from "hooks/useFirestore";

const CustomOrgListing = [
  "Growthbeans",
  "SUSS",
  "NG",
  "TAFEP",
  "NTU Career & Attachment Office",
  "Edhaven Event",
  "ETP2271",
  "SGH Peer Circles",
];

const NGFinished = () => {
  const navigate = useNavigate();
  const { growthCircleSession } = useGrowthCircleContext();
  const [hasTrigger, setHasTrigger] = useState(false);
  const { profile } = useAuthContext();
  const { updateDocument } = useFirestore("GrowthCircles");

  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const currentStep = 5;

  const nextHandler = () => {
    navigate(HOME_PAGE);
  };

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  useEffect(() => {
    if (profile?.uid === growthCircleSession?.facilOwner) {
      updateDocument(growthCircleSession?.uid, { Activity: [] });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <NoGCSessionWrapper>
      <PageWrapper pageType={"groundRules"}>
        <Header hasModal={hasTrigger} />
        <FiveStepHeader
          hasModal={hasTrigger}
          hasLightning={true}
          hasGuide={false}
          currentStep={currentStep}
          text="Completed"
          setIsTriggerVisible={_setTrigger}
        />
        <div className="relative w-full">
          <ContentWrapper hasFiveStepper={true}>
            {/* TODO: convert to organisation */}
            {growthCircleSession?.type === "NG" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={`font-semibold text-xl py-3`}
                    >
                      Thank you for joining{" "}
                      <span className="whitespace-nowrap">
                        How To Art With Friends
                      </span>{" "}
                      <span className="italic">Growth Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      Join another Growth Circle to try out other activities
                      from{" "}
                      <span className="whitespace-nowrap">
                        How to Art with Friends
                      </span>{" "}
                      or pick up the guide from our Visitor Services Counters!
                    </p>
                    <div className="flex gap-1 justify-center items-center w-full py-4 px-4">
                      <img
                        src={GrowthCollectiveLogo}
                        alt="Growth Collective SG"
                        width={155}
                      />
                      <img
                        src={NationalGalleryLogo}
                        alt="National Gallery SG"
                        width={280}
                      />
                    </div>
                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2 text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "SUSS" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-full flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-md py-3`}
                    >
                      Thank you for joining the SUSS
                      <span className="whitespace-nowrap block w-full text-3xl">
                        PSY213
                      </span>{" "}
                      <span className="italic">Growth Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      We look forward to you joining the next Growth Circle. If
                      you are keen to join other Growth Circles outside of this
                      module,{" "}
                      <a
                        className="text-sky-500 font-semibold"
                        href=" https://linktr.ee/growthcollectivesg"
                        target="_blank"
                      >
                        click here
                      </a>
                    </p>
                    <div className="flex gap-1 justify-center items-center w-full py-4 px-4">
                      <img
                        src={GrowthCollectiveLogo}
                        alt="Growth Collective SG"
                        width={155}
                      />
                      <img src={SUSSLogo} alt="SUSS Logo" width={180} />
                    </div>
                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4  w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}
            {growthCircleSession?.type === "Growthbeans" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-xl py-3`}
                    >
                      Thank you for joining{" "}
                      <span className="italic">Growth Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      We hope you enjoyed your experience at the Growth Circles.
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "TAFEP" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-xl py-3`}
                    >
                      Thank you for joining{" "}
                      <span className="italic">Growth Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      We hope you enjoyed your experience at the Growth Circles.
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "NTU Career & Attachment Office" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-2xl py-3`}
                    >
                      Hurray you’ve completed one run today!
                    </h1>

                    <img src={NTULogo} alt="NTU" width={150} height={150} />

                    <h2
                      style={{ color: "var(--main-colour)" }}
                      className="italic text-xl text-center py-4 font-bold"
                    >
                      {" "}
                      BuddyUP! Growth Mentoring Circles
                    </h2>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      For more support, visit career axis website{" "}
                      <a
                        className="text-sky-500"
                        href="https://careeraxis.ntu.edu.sg/students"
                        target="_new"
                      >
                        https://careeraxis.ntu.edu.sg/students
                      </a>{" "}
                      or the
                      <Link to={"/resources"} className="text-sky-500">
                        {" "}
                        resource page
                      </Link>
                    </p>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      To see past sessions:
                      <br /> Go to your Journal{" "}
                      <Link
                        style={{ color: "var(--main-colour)" }}
                        to={"/journal"}
                        className="font-bold"
                      >
                        {" "}
                        here
                      </Link>
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "Edhaven Event" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-2xl py-3`}
                    >
                      Thank you for being part of Edhaven Growth Circles today!
                    </h1>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      Find out more about upcoming Growth Circles from the links
                      in
                      <Link to={"/resources"} className="text-sky-500">
                        {" "}
                        resource page
                      </Link>
                    </p>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      To revisit your reflections from today, or give another
                      word of appreciation to your peers, go to your Journal{" "}
                      <Link
                        style={{ color: "var(--main-colour)" }}
                        to={"/journal"}
                        className="font-bold"
                      >
                        {" "}
                        here.
                      </Link>{" "}
                      We look forward to connect with you again soon!
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "ETP2271" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-2xl py-3`}
                    >
                      Hurray, you’ve completed a discovery circle today!
                    </h1>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      To reflect deeper on a topic, thought or emotion, consider
                      using a
                      <Link to={"/self-discovery"} className="text-sky-500">
                        {" "}
                        Self Discovery tool
                      </Link>
                    </p>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      To revisit your reflections from today, or give another
                      word of appreciation to your peers, go to
                      <Link
                        style={{ color: "var(--main-colour)" }}
                        to={"/journal"}
                        className="font-bold"
                      >
                        {" "}
                        My Journals
                      </Link>{" "}
                    </p>

                    <p className="mt-2 text-center  text-slate-800 py-4">
                      To chat with your coach, revisit course information or
                      stay on top of course work, visit{" "}
                      <a
                        href="https://growthbeans.coursemology.org/"
                        className="text-sky-500"
                        target="_new"
                      >
                        {" "}
                        Coursemology.
                      </a>{" "}
                      You may also access this from
                      <Link
                        style={{ color: "var(--main-colour)" }}
                        to={"/resources"}
                        className="font-bold"
                      >
                        {" "}
                        Resources
                      </Link>{" "}
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession?.type === "SGH Peer Circles" && (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-xl py-3`}
                    >
                      Thank you for joining <br />{" "}
                      <span className="italic">SGH Peer Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      We hope you enjoyed your experience at the SGH Peer
                      Circles.
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            )}

            {growthCircleSession &&
            !CustomOrgListing.includes(growthCircleSession?.type) ? (
              <Center>
                <div
                  className={`bg-white flex flex-col justify-start items-center w-full h-auto rounded-xl shadow-lg] py-4  px-4 md:px-14 shadow-lg`}
                >
                  <div className="w-3/4 flex flex-col justify-start items-center">
                    <h1
                      style={{ color: "var(--main-colour)" }}
                      className={` font-semibold text-xl py-3`}
                    >
                      Thank you for joining{" "}
                      <span className="italic">Growth Circles</span>.
                    </h1>

                    <p className="mt-2 text-center   text-slate-800">
                      We hope you enjoyed your experience at the Growth Circles.
                    </p>

                    <button
                      onClick={nextHandler}
                      style={{
                        color: "var(--main-colour)",
                        borderColor: "var(--main-colour)",
                      }}
                      className={`px-4 py-2  text-xl font-bold border-4 w-3/4 shadow-lg mx-auto mt-5 rounded-full  hover:text-white duration-1000 transition `}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
                <NGFooter type={growthCircleSession?.type} />
              </Center>
            ) : (
              ""
            )}
          </ContentWrapper>
        </div>
      </PageWrapper>
    </NoGCSessionWrapper>
  );
};

export default NGFinished;
