import classes from "./SettingsIntentions.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import SubmitIntention from "components/SettingIntentions/SubmitIntention";
import Instruction from "components/utility/Instruction";
import React, { useEffect, useState } from "react";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationIntentions } from "hooks/useOrganisationIntentions";
import { Intention } from "interface/IntentionInterface";
import { IoMdRefreshCircle } from "react-icons/io";
import OthersIntentionRandom from "components/SettingIntentions/OthersIntentionRandom";

export default function SettingRandomIntentionsComponent({
  backAndNextHandler,
}: ComponentProps) {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { intentions } = useOrganisationIntentions();
  const [question, setQuestion] = useState("");

  const randomizeIntentionQuestion = () => {
    try {
      if (intentions) {
        const data = intentions as Intention[];
        if (data.length > 0) {
          // Map the data to an array of strings
          const questionStrings = data.map((item: Intention) => item.question);

          // Fisher-Yates shuffle algorithm
          for (let i = questionStrings.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [questionStrings[i], questionStrings[j]] = [
              questionStrings[j],
              questionStrings[i],
            ];
          }

          // Select a maximum of 3 questions
          const selectedQuestions = questionStrings[0];

          setQuestion(selectedQuestions); // Store the selected questions in the component's state
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    if (selectedOrganisation.randomizeIntentions) {
      randomizeIntentionQuestion();
    }
    // eslint-disable-next-line
  }, [selectedOrganisation, intentions]);

  useEffect(() => {}, [question]);

  return (
    <React.Fragment>
      <EmptySpace height={"2rem"} />

      {selectedOrganisation && selectedOrganisation.randomizeIntentions && (
        <div
          className={`${classes.container} flex items-center justify-center`}
        >
          <div className="w-1/2">
            <Instruction text={question} />
          </div>
          <button onClick={randomizeIntentionQuestion}>
            <IoMdRefreshCircle
              size={35}
              style={{ color: "var(--main-colour)" }}
            />
          </button>
        </div>
      )}
      <EmptySpace height={"2rem"} />
      <SubmitIntention profile={profile} question={question} />
      <EmptySpace height={"5px"} />
      <OthersIntentionRandom
        randomize={
          (selectedOrganisation && selectedOrganisation.randomizeIntentions) ||
          false
        }
      />
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
}
