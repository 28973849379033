import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface Props {
  show: boolean
}

export default function RequestVerifyEmailModal({ show }: Props) {
  const navigate = useNavigate();

  const verifyHandler = async () => {
    navigate("/account/email-verification-form");
  };

  return (
    <Modal
      show={show}
      size="md"
      className="min-h-screen"
      popup={true}
    >
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-2 h-24 w-24 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
            GrowthCircle Alert
            <small className="block text-sm mt-2">
              It seems that your account has not yet been verified. To gain
              further access to this feature, please complete the
              verification process by clicking on the 'Verify' button and
              checking your email
            </small>
          </h3>

          <div className="flex justify-center gap-4">
            <Button
              disabled={false}
              className="shadow-md w-full text-2xl"
              style={{ backgroundColor: "var(--main-colour)" }}
              onClick={verifyHandler}
            >
              Verify
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );  
}
