import RectangularBubble from "components/utility/RectangularBubble";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentTypes from "components/GroundRules/ContentTypes";
import LarefSessionTemplate from "components/utility/SessionTemplate/LarefSessionTemplate";
import { useAuthContext } from "hooks/useAuthContext";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { updateWindowLocationPath } from "models/profile";
import Acknowledgements from "components/SUSS/Acknowledgement";

/**
 * Page that shows the ground rules for users when they first start a GC session
 */
export default function GroundRules() {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const [contentType, setContentType] = useState("Mindful");
  const navigate = useNavigate();

  const handleNext = (currentContentType: string) => {
    switch (currentContentType) {
      case "Mindful":
        setContentType("Invitation");
        break;
      case "Invitation":
        setContentType("Expect");
        break;
      case "Expect":
        navigate("/growthcircle");
        break;
    }
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
  };

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  useEffect(() => {
    if (!growthCircleSession) return;
  }, [growthCircleSession]);

  return (
    <>
      <Acknowledgements />
      <div className="relative">
        <LarefSessionTemplate
          hasModal={true}
          stepperContent={ContentTypes[contentType].stepperContent}
        >
          <RectangularBubble
            key={ContentTypes[contentType].id}
            id={ContentTypes[contentType].id}
            title={ContentTypes[contentType].title}
            subtitle={ContentTypes[contentType].subtitle}
            buttonText={ContentTypes[contentType].buttonText}
            buttonMethod={() => handleNext(contentType)}
          >
            {ContentTypes[contentType].content}
          </RectangularBubble>
        </LarefSessionTemplate>
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </>
  );
}
