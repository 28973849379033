import { Link } from "react-router-dom";
import Button from "components/utility/Button";
// import { ReactComponent as GoogleLogin } from "assets/GoogleLogin.svg";
// import { ReactComponent as FacebookLogin } from "assets/FacebookLogin.svg";
// import { ReactComponent as LinkedInLogin } from "assets/LinkedInLogin.svg";

// import { ReactComponent as GrowingBean } from "../../assets/GrowingBean.svg";
import PageWrapper from "components/utility/PageWrapper";
import InputField from "components/utility/Forms/InputField";
import LoadingWidget from "components/utility/LoadingWidget";
import classes from "./LoginLanding.module.css";
//import hooks
import React, { FormEvent, useState } from "react";
import { Modal } from "flowbite-react";
import { useLogin } from "hooks/useLogin";
import useUsers from "hooks/useUsers";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { Profile } from "interface/ProfileInterface";
import { IoMdCloseCircle } from "react-icons/io";
import Sage from "assets/BeanSquad/sage.png";
import LoginWelcomeMessage from "components/Login/LoginWelcomeMessage";

const buttonStyle = {
  backgroundColor: "#FFF",
  fontSize: "1.1em",
};

// const loginMethodsStyle = {
//   margin: "10px 20px",
// };

// const wrapperStyle = {
//   gap: "3rem",
// };

const LoginLanding = () => {
  const { isPending, loginGoogle } = useLogin();
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { fetchSignInMethods, checkUserFunction } = useUsers();
  const { login, error: loginError, dataSyncOldToNew } = useLogin();
  const [isPendingLogin, setIsPendingLogin] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<Profile | undefined>();
  const [userName, setUsername] = useState("");

  // const emailRef = useRef<>(null);
  // const passwordRef = useRef(null);
  // const faceBookHandler = (e) => {
  //   e.preventDefault();
  //   loginFacebook();
  // };

  const googleHandler = (e) => {
    e.preventDefault();
    loginGoogle();
  };

  const viewHandler = () => {
    setVisible(!visible);
    setEmail("");
    setSecondStep(false);
    setThirdStep(false);
    setError(false);
    setErrorMessage("");
  };

  const nextHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPendingLogin(true);
    const signInMethods = await fetchSignInMethods(email);

    setIsPendingLogin(false);
    if (
      signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)
    ) {
      setSecondStep(true);
      setError(false);
      setErrorMessage("");
    } else if (
      signInMethods.includes(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD)
    ) {
      setError(true);
      setErrorMessage(
        `To access your account, please log in using your google account instead of Email and Password.`
      );
    } else if (
      signInMethods.includes(FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD)
    ) {
      setError(true);
      setErrorMessage(
        `To access your account, please log in using your facebook account instead of Email and Password.`
      );
    } else {
      const data = await checkUserFunction(email);

      if (data && data.accountType === "guest") {
        setThirdStep(true);
        setError(false);
        setErrorMessage("");
        // store the current users data prepare for sync
        setCurrentUserData(data);
        setUsername(data.displayName);
      } else {
        setError(true);
        setErrorMessage("Account does not exist");
      }
    }
  };

  // const processGuest = async (data: Profile) => {
  //   anonyMousLogin(data.email, data.displayName, data.growthCircle, data.uid);
  // };

  const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPendingLogin(true);
    await login(email, password);
    if (loginError) {
      setError(true);
      setErrorMessage(loginError);
    } else {
      setError(true);
      setErrorMessage("Login failed");
    }
    setIsPendingLogin(false);
  };

  const submitHandlerSetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPendingLogin(true);
    if (userName !== "") {
      if (password !== "" && password2 !== "") {
        if (password === password2) {
          if (currentUserData) {
            dataSyncOldToNew(currentUserData, userName, password);
          }
        } else {
          setError(true);
          setErrorMessage(
            "The password and the confirmed password entered do not match."
          );
        }
      } else {
        setError(true);
        setErrorMessage("Password is required.");
      }
    } else {
      setError(true);
      setErrorMessage("Username is required.");
    }

    setIsPendingLogin(false);
  };

  const getRefinedFirebaseAuthErrorMessage = (
    errorMesssage: string
  ): string => {
    return errorMesssage.replace("Firebase: ", "").replace(/\(auth.*\)\.?/, "");
  };

  return (
    <>
      <React.Fragment>
        <Modal show={visible} size="lg" popup={true} className="min-h-screen">
          <Modal.Body className="bg-orange-100 relative h-auto flex justify-center items-center rounded-lg">
            <div className="absolute right-0 top-0 p-2">
              <IoMdCloseCircle
                size={25}
                style={{ color: "var(--main-colour)" }}
                className="cursor-pointer"
                onClick={viewHandler}
              />
            </div>
            <form
              className="text-center flex flex-col justify-center items-center py-6"
              onSubmit={nextHandler}
            >
              {!secondStep && !thirdStep && (
                <div className="pl-10">
                  {error && (
                    <small className="text-red-500">{errorMessage}</small>
                  )}

                  <InputField
                    text="Email"
                    type={"email"}
                    onChange={(e: any) => setEmail(e.target.value)}
                    value={email}
                    autoFocus={true}
                  />

                  <Button
                    text={isPendingLogin ? "Loading..." : "Next"}
                    isDisabled={email === "" ? true : false}
                    onClick={nextHandler}
                    style={buttonStyle}
                    type="button"
                  />
                </div>
              )}
            </form>
            <form
              className="text-center flex flex-col justify-center items-center py-6"
              onSubmit={submitHandler}
            >
              {secondStep && (
                <div className="pl-10">
                  {error && (
                    <small className="text-red-500">
                      {getRefinedFirebaseAuthErrorMessage(errorMessage)}
                    </small>
                  )}

                  <InputField
                    text="password"
                    type={"password"}
                    onChange={(e: any) => setPassword(e.target.value)}
                    value={password}
                    autoFocus={true}
                  />

                  <Button
                    text={isPendingLogin ? "Loading..." : "Login"}
                    isDisabled={email === "" || isPendingLogin ? true : false}
                    onClick={submitHandler}
                    style={buttonStyle}
                    type="button"
                  />
                </div>
              )}
            </form>

            <form
              className="p-6 text-center flex flex-col justify-center items-center"
              onSubmit={submitHandlerSetPassword}
            >
              {thirdStep && (
                <>
                  <h3 className="py-4">
                    We have identified that you are using an outdated user
                    login. Kindly update your account by specifying a username
                    and a new password.
                  </h3>
                  {error && (
                    <small className="text-red-500">
                      {getRefinedFirebaseAuthErrorMessage(errorMessage)}
                    </small>
                  )}

                  <InputField
                    text="Username"
                    type={"text"}
                    onChange={(e: any) => setUsername(e.target.value)}
                    value={userName}
                    autoFocus={true}
                  />

                  <InputField
                    text="password"
                    type={"password"}
                    onChange={(e: any) => setPassword(e.target.value)}
                    value={password}
                    autoFocus={false}
                  />

                  <InputField
                    text="Confirm password"
                    type={"password"}
                    onChange={(e: any) => setPassword2(e.target.value)}
                    value={password2}
                    autoFocus={false}
                  />

                  <Button
                    text={isPendingLogin ? "Loading..." : "Login"}
                    isDisabled={email === "" || isPendingLogin ? true : false}
                    onClick={submitHandlerSetPassword}
                    style={buttonStyle}
                    type="button"
                  />
                </>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      <PageWrapper>
        <div className="min-h-[80vh] my-8 flex flex-col justify-center items-center">
          {isPending && <LoadingWidget />}
          {!isPending && (
            <>
              <LoginWelcomeMessage />

              <div className={`${classes["existing-user"]} overflow-visible`}>
                <span className="text-standard">
                  {"\n"}Already a <strong>GrowthCircle </strong>user?
                  <span className="block">Click the mascot below!</span>
                </span>
                <div
                  onClick={viewHandler}
                  className="w-44 my-8 cursor-pointer temporary-bounce"
                >
                  <img src={Sage} alt="sage" />
                </div>
              </div>
              <div className={`${classes["login-type"]} w-full `}>
                <span>OR login with:</span>
                {/* <span>
                  <button
                    className={classes["btn-link"]}
                    onClick={faceBookHandler}
                  >
                    <FacebookLogin style={loginMethodsStyle} />
                  </button>
                  <button
                    className={classes["btn-link"]}
                    onClick={googleHandler}
                  >
                    <GoogleLogin style={loginMethodsStyle} />
                  </button>
                  <button className={classes["btn-link"]}>
                    <LinkedInLogin style={loginMethodsStyle} />
                  </button>
                </span> */}
                <div
                  className="bg-white shadow-md rounded-full my-4 w-full flex items-center justify-center py-4 cursor-pointer gap-2"
                  onClick={googleHandler}
                >
                  <svg
                    fill="none"
                    height="32"
                    viewBox="0 0 24 24"
                    width="32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21.8055 10.0415h-.8055v-.0415h-9v4h5.6515c-.8245 2.3285-3.04 4-5.6515 4-3.3135 0-6-2.6865-6-6s2.6865-6 6-6c1.5295 0 2.921.577 3.9805 1.5195l2.8285-2.8285c-1.786-1.6645-4.175-2.691-6.809-2.691-5.5225 0-10 4.4775-10 10s4.4775 10 10 10 10-4.4775 10-10c0-.6705-.069-1.325-.1945-1.9585z"
                      fill="#ffc107"
                    ></path>
                    <path
                      d="m3.15295 7.3455 3.2855 2.4095c.889-2.201 3.042-3.755 5.56155-3.755 1.5295 0 2.921.577 3.9805 1.5195l2.8285-2.8285c-1.786-1.6645-4.175-2.691-6.809-2.691-3.84105 0-7.17205 2.1685-8.84705 5.3455z"
                      fill="#ff3d00"
                    ></path>
                    <path
                      d="m12 22c2.583 0 4.93-.9885 6.7045-2.596l-3.095-2.619c-1.004.7605-2.252 1.215-3.6095 1.215-2.60097 0-4.80947-1.6585-5.64147-3.973l-3.261 2.5125c1.655 3.2385 5.016 5.4605 8.90247 5.4605z"
                      fill="#4caf50"
                    ></path>
                    <path
                      d="m21.8055 10.0415h-.8055v-.0415h-9v4h5.6515c-.396 1.1185-1.1155 2.083-2.0435 2.7855.0005-.0005.001-.0005.0015-.001l3.095 2.619c-.219.199 3.2955-2.4035 3.2955-7.4035 0-.6705-.069-1.325-.1945-1.9585z"
                      fill="#1976d2"
                    ></path>
                  </svg>{" "}
                  <p className="text-xl text-slate-500">Google</p>
                </div>
              </div>
              <span className={classes.signup}>
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  className={`underline font-semibold text-green-300`}
                >
                  Sign up now
                </Link>
                <span className="block text-center mt-2">
                  <Link to="/forget-password" className={classes.link}>
                    Forgot password?
                  </Link>
                </span>
              </span>
            </>
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default LoginLanding;
