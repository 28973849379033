import { useState, useEffect, useCallback } from "react";
import { Profile } from "interface/ProfileInterface";

import {
  projectAuth,
  projectFirestore,
  projectAuthGoogle,
  signInWithPopup,
  getAuth,
  timestamp,
  signInWithCustomToken,
  sendPasswordResetEmail,
} from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { useEmailAPI } from "./useEmailAPI";
import { getUser, processUserSignIn } from "models/profile";
import {
  acceptInvitations,
  getOrgByName,
  joinOrganisation,
} from "models/organisation";
import useUser from "./useUser";
import useGCJournal from "./useGCJournal";
import useUsers from "./useUsers";
import useMassCRUD from "./useMassCRUD";
import { facilitatorRole, participantRole } from "models/organisationRole";
import { useSignup } from "./useSignup";
import { defaultOrganisation } from "interface/OrganisationInterface";
import { useFirestore } from "./useFirestore";
import { Timestamp } from "firebase/firestore";

export const useLogin = () => {
  const { usernameError, emailError, passwordError, signup } = useSignup();
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [resetSent, setResetSent] = useState<boolean>(false);
  const { dispatch } = useAuthContext();
  const { sendEMailVerification } = useEmailAPI();
  const { synOldProfileToNew } = useUser();
  const { massUpdateJournals } = useGCJournal();
  const { checkUserFunction, fetchSignInMethods } = useUsers();
  const { updateGCFacilOwner } = useMassCRUD();
  const { updateDocument } = useFirestore("users");

  const _processUserSignIn = useCallback(
    async (
      signInProcess: () => Promise<UserCredential>,
      accountType: string,
      growthCircle?: string
    ) => {
      setIsPending(true);
      try {
        const userCredential = await signInProcess();
        const firebaseUser = userCredential.user;
        if (!firebaseUser) {
          return;
        }
        const newProfile: Partial<Profile> = {
          online: true,
          email: firebaseUser.email ? firebaseUser.email : "",
          displayName: firebaseUser.displayName ? firebaseUser.displayName : "",
          displayNameLowerCase: firebaseUser.displayName
            ? firebaseUser.displayName.toLowerCase()
            : "",
          accountType,
          last_active: timestamp.fromDate(new Date()),
          uid: firebaseUser.uid,
          id: firebaseUser.uid,
          isFacil: false,
        };
        if (growthCircle) {
          newProfile.growthCircle = growthCircle;
        }

        await processUserSignIn(userCredential, newProfile);
        const userId = firebaseUser.uid;
        const profile = await getUser(userId);
        if (profile) {
          updateDocument(profile.id, { growthCircle, pageNumber: 1 });
          acceptInvitations(profile);
          const org = await getOrgByName(defaultOrganisation.name);
          if (org) {
            const role = profile.isFacil
              ? facilitatorRole.name
              : participantRole.name;
            joinOrganisation(org, profile.uid, role);
          }
        }

        if (growthCircle) {
          const _profile = await getUser(userId);
          dispatch({
            type: "LOGIN",
            payload: firebaseUser,
            profile: _profile ?? null,
          });
        } else {
          dispatch({
            type: "LOGIN",
            payload: firebaseUser,
            profile: profile ?? null,
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setIsPending(false);
      }
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const login = useCallback(
    async (email: string, password: string, growthCircle?: string) => {
      const signInProcess = () =>
        signInWithEmailAndPassword(projectAuth, email, password);
      return _processUserSignIn(
        signInProcess,
        "email_and_password",
        growthCircle
      );
    },
    [_processUserSignIn]
  );

  const loginFacebook = useCallback(async () => {
    const signInProcess = () => signInWithPopup(getAuth(), projectAuthGoogle);
    return _processUserSignIn(signInProcess, "facebook");
  }, [_processUserSignIn]);

  const loginGoogle = useCallback(
    async (growthCircle?: string, email?: string) => {
      const signInProcess = () => signInWithPopup(getAuth(), projectAuthGoogle);
      return _processUserSignIn(signInProcess, "google", growthCircle);
    },
    [_processUserSignIn]
  );

  const signInUsingUID = useCallback(
    async (id: string, gc: string) => {
      const signInProcess = () => signInWithCustomToken(getAuth(), id);
      return _processUserSignIn(signInProcess, "", gc);
    },
    [_processUserSignIn]
  );

  const forgotPassword = (email: string) => {
    const auth = getAuth();
    setError(null);
    setIsPending(true);

    try {
      sendPasswordResetEmail(auth, email)
        .then(function () {
          // Email sent.
          console.log("email sent");
          setIsPending(false);
          setError(null);
          setResetSent(true);
        })
        .catch(function (err) {
          // An error happened.
          console.log(err.message);
          if ((err.code = "auth/user-not-found")) {
            setIsPending(false);
            setError(
              "There is no user record corresponding to this identifier. The user may have been deleted."
            );
            setResetSent(false);
          } else {
            setIsPending(false);
            setError(err.message);
            setResetSent(false);
          }
        });
    } catch (err) {
      setIsPending(false);
      let msg = "";
      if (err instanceof Error) msg = err.message;
      setError(msg);
      setResetSent(false);
    }
  };

  /**
   * The `InfoPanelLogin` function handles the login and account creation process, checking for
   * existing profiles and different sign-in methods.
   * @param {string} email - The email parameter is a string that represents the user's email address.
   * @param {string} displayName - The `displayName` parameter is a string that represents the name or
   * username of the user. It is used to display the user's name in the application.
   * @param {any} gc - The `gc` parameter is used to specify the growth circle for the user. It is of
   * type `any`, which means it can accept any value.
   * @param {string} password - The `password` parameter is a string that represents the password for
   * the user's account.
   * @param {string} [personalID] - The `personalID` parameter is an optional string that represents
   * the personal identification of the user. It can be used to store additional information about the
   * user, such as an identification number or code. If provided, it will be included in the user's
   * profile object. If not provided, it will default
   * @param {string} [tutorialGroup] - The `tutorialGroup` parameter is an optional string that
   * represents the tutorial group of the user. It is used in the process of creating a new user
   * profile. If provided, it will be stored in the `tutorialGroup` field of the user's profile object.
   * If not provided, the `tutorial
   */
  const InfoPanelLogin = async (
    email: string,
    displayName: string,
    gc: any,
    password: string,
    personalID?: string,
    tutorialGroup?: string,
    OrganisationFields?: any
  ) => {
    setError(null);
    setIsPending(true);

    //check what method its using
    const signInMethods = await fetchSignInMethods(email);
    const data = await checkUserFunction(email);

    //check if profile exist

    // if data exist and accountType is guest it will convert it to email and password using the dataSyncOldToNew

    if (data) {
      if (
        signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)
      ) {
        var id = data.uid;
        var requestOptions: RequestInit = {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        };
        setIsPending(true);
        fetch(
          `https://us-central1-nobeans-110a4.cloudfunctions.net/createCustomToken?id=${id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            signInUsingUID(data, gc);
            setTimeout(() => {
              setIsPending(false);
              setError(null);
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
            setTimeout(() => {
              setIsPending(false);
              setError(null);
            }, 2000);
          });
      } else if (
        signInMethods.includes(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD)
      ) {
        //implement

        setIsPending(false);
        setError(
          "Unable to log in or create an account because your email is already registered and linked to the Google sign-in method. Please log in using your Google account before proceeding."
        );
      } else if (
        signInMethods.includes(FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD)
      ) {
        //implement

        setIsPending(false);
        setError(
          "Unable to log in or create an account because your email is already registered and linked to the Facebook sign-in method. Please log in using your Facebook account before proceeding."
        );
      } else {
        if (data && data.accountType === "guest") {
          dataSyncOldToNew(data, displayName, password);
        }
      }
    } else {
      // if the data doest not exist meaning its a new user so it will create a new user provider and profile object
      try {
        const userUpdates = {
          growthCircle: gc,
          personalID: personalID ?? "",
          tutorialGroup: tutorialGroup ?? "",
          OrganisationFields,
        };

        const userCred = await signup(
          email,
          password,
          displayName,
          userUpdates
        );

        if (!userCred) {
          return "error";
        }
      } catch (err) {
        let msg = "";
        if (err instanceof Error) msg = err.message;
        setError(msg);
      } finally {
        if (usernameError) {
          setError((e) => `${e ?? ""} ${usernameError}`);
        }
        if (passwordError) {
          setError((e) => `${e ?? ""} ${passwordError}`);
        }
        if (emailError) {
          setError((e) => `${e ?? ""} ${emailError}`);
        }
        setIsPending(false);
      }
    }
  };

  /**
   * The function `dataSyncOldToNew` is an asynchronous function that performs a series of steps to
   * synchronize old user data to a new user profile, including creating a new user, updating user
   * profile information, creating a user document, updating journals and growth circles, transferring
   * progress to the new user, deleting the old profile, and logging the user in.
   * @param {Profile} currentDataObject - The currentDataObject parameter is an object that represents
   * the current user profile data. It contains properties such as email, displayName, photoURL,
   * firstname, lastname, gender, birthday, accountType, friendRequests, referral, grow, last_active,
   * uid, id, progress, isFacil, age
   * @param {string} userName - The `userName` parameter is a string that represents the display name of
   * the user. It is used to update the display name of the user in the authentication system and the
   * user document in the database.
   * @param {string} password - The `password` parameter is a string that represents the password for
   * the user authentication.
   */
  const dataSyncOldToNew = async (
    currentDataObject: Profile,
    userName: string,
    password: string
  ) => {
    //Step 1: Create email and password user auth
    const res = await createUserWithEmailAndPassword(
      projectAuth,
      currentDataObject.email,
      password
    );

    if (!res || !res.user) {
      throw new Error("Could not complete signup");
    }

    // add display name to user
    await updateProfile(res.user, { displayName: userName });

    //create a user document
    const newUser: Profile = {
      online: true,
      email: currentDataObject.email,
      displayName: userName,
      displayNameLowerCase: userName.toLowerCase(),
      photoURL: "",
      firstname: "",
      lastname: "",
      gender: "",
      birthday: "",
      accountType: "email_and_password",
      friendRequests: [],
      referral: [],
      grow: [],
      last_active: timestamp.fromDate(new Date()) as Timestamp,
      createdAt: timestamp.fromDate(new Date()) as Timestamp,
      uid: res.user.uid,
      id: res.user.uid,
      progress: {},
      isFacil: false,
      age: "",
      growthCircle: "",
      oldUID: currentDataObject.uid,
      hasStrongPassword: true,
      OrganisationFields: currentDataObject.OrganisationFields,
    };
    await projectFirestore.collection("users").doc(res.user.uid).set(newUser);
    acceptInvitations(newUser);
    sendEMailVerification(currentDataObject.email, userName, res.user.uid);

    //Step 2 : Get and update Journals old userID
    massUpdateJournals(currentDataObject.uid, res.user.uid);

    //Step 3 : Get and update GrowthCircles facilOwner if user is facil
    updateGCFacilOwner(currentDataObject.uid, res.user.uid);

    //Step 4 : Update user profile UID and transfer progress to new UID
    synOldProfileToNew(currentDataObject.uid, res.user.uid, userName);

    //Step 5 : Delete old profile
    projectFirestore.collection("users").doc(currentDataObject.uid).delete();

    //Step 6 : Log user in
    dispatch({ type: "LOGIN", payload: res.user, profile: newUser });
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    login,
    isPending,
    error,
    InfoPanelLogin,
    loginFacebook,
    loginGoogle,
    forgotPassword,
    signInUsingUID,
    dataSyncOldToNew,
    resetSent,
    isCancelled,
  };
};
