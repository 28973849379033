import toast from "react-hot-toast";

import { useEffect, useRef, useState } from "react";

import SimpleLoader from "components/Loaders/SimpleLoader";
import Organisation from "interface/OrganisationInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import usePromise from "hooks/utility/usePromise";
import { AiFillCloseCircle } from "react-icons/ai";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useFirestore } from "hooks/useFirestore";
import { uploadSingImage } from "utility/uploadHandling";
import { CropperRef, Cropper, ImageRestriction } from "react-advanced-cropper";
import { projectStorage } from "../../../firebase/config";

interface UploadLogoProps {
  OrganisationTagLine: string;
  setImageURLsData: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOrganisation: Organisation;
}

const GrowthCircleUploadLogo = ({
  setImageURLsData,
  selectedOrganisation,
  OrganisationTagLine,
}: UploadLogoProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { isLoading, resolve } = usePromise();
  const [image, setImage] = useState(selectedOrganisation.logo ?? "");
  const [isVisible, setIsVisible] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [dataUrl, setDataUrl] = useState<string>("");
  const [restricted, setRestricted] = useState(false);
  const { updateDocument } = useFirestore("organisations");
  const { updateSelectedOrganisation } = useOrganisationContext();

  const changeHandler = (e: any) => {
    e.preventDefault();
    let files: Blob[] = [];
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      setCropping(true);
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    if (selectedOrganisation.logo) {
      setImage(selectedOrganisation.logo);
    } else {
      setImage("");
    }

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {}, [dataUrl, image]);

  const dataURLtoFile = async () => {
    const filename = `${selectedOrganisation.id}_cropped.png`;
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      throw new Error("Invalid data URL");
    }

    const mime = arr[0].match(/:(.*?);/);
    if (!mime) {
      throw new Error("Invalid data URL format");
    }
    const mimeType = mime[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const FinalFile = new File([u8arr], filename, { type: mimeType });

    const newImgURL = await resolve(() =>
      uploadSingImage(
        FinalFile,
        `/organisations/logo/${selectedOrganisation.id}`
      )
    );

    if (!newImgURL) return;
    await resolve(() =>
      updateDocument(selectedOrganisation.id, { logo: newImgURL })
    ).then(() => {
      setCropping(false);
    });

    await resolve(() => updateSelectedOrganisation({ logo: newImgURL }));

    return FinalFile;
  };

  const deleteHandler = async () => {
    const storageRef = projectStorage.ref(
      `/organisations/logo/${selectedOrganisation.id}`
    );
    storageRef.listAll().then((listResults) => {
      const promises = listResults.items.map((item) => {
        return item.delete();
      });
      Promise.all(promises);
    });

    await resolve(() =>
      updateDocument(selectedOrganisation.id, { logo: null })
    ).then(() => {
      setIsVisible(!isVisible);
      toast.success("image deleted successfully!");
      setImage("");
      updateSelectedOrganisation({ logo: null });
    });
  };

  const setDeleteHandler = (img: string) => {
    setIsVisible(!isVisible);
    setCropping(false);
  };

  const onChange = (cropper: CropperRef) => {
    if (cropper) {
      const coordinates = cropper.getCoordinates();
      if (coordinates && coordinates.width !== undefined) {
        if (coordinates.width < 50) {
          setRestricted(true);
          return;
        }
      }

      if (coordinates && coordinates.height !== undefined) {
        if (coordinates.height < 50) {
          setRestricted(true);
          return;
        }
      }
      setRestricted(false);
      setDataUrl(cropper.getCanvas()?.toDataURL() ?? "");
    }
  };

  return (
    <>
      <Modal show={isVisible} size="lg">
        <div
          className={`${classes["container"]}  min-h-[20vh] relative p-5 text-center flex flex-col justify-center`}
        >
          <AiFillCloseCircle
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
            onClick={() => setIsVisible(!isVisible)}
            className="absolute top-2 right-2 cursor-pointer"
          />
          <HiOutlineExclamationCircle
            size={80}
            className="mx-auto   text-gray-400 dark:text-gray-200"
          />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete this image?
          </h3>
          {!isLoading ? (
            <div className="flex justify-center items-center gap-4">
              <button
                className={`${classes["button"]} min-h-[50px]`}
                color="failure"
                onClick={deleteHandler}
              >
                Yes, I'm sure
              </button>
              <button
                className={`${classes["button"]} min-h-[50px]`}
                color="gray"
                onClick={() => setIsVisible(!isVisible)}
              >
                No, cancel
              </button>
            </div>
          ) : (
            <div className="flex justify-center items-center gap-4">
              <SimpleLoader center />{" "}
            </div>
          )}
        </div>
      </Modal>
      <div
        general-settings-tour="organisation-logo"
        className="my-4 p-4  border-2 border-gray-200 flex-1"
      >
        <p className="font-semibold  py-4 text-xl">Upload Organisation Logo</p>

        <div className="w-full md:w-1/2">
          <input
            type="file"
            style={{ height: "45px" }}
            className="bg-white rounded-full file:h-12"
            accept="image/jpeg, image/png, image/gif"
            onChange={changeHandler}
            ref={inputFileRef}
          />
        </div>

        <p
          style={{ color: "var(--text-colour)" }}
          className="font-semibold py-2"
        >
          Files allowed are (png, jpeg, gif, bmp)
        </p>

        {image && (
          <div className="flex gap-4 my-4">
            {image && (
              <button
                className={`${classes["button"]}`}
                onClick={() => setDeleteHandler(image)}
              >
                Remove
              </button>
            )}

            <button
              className={`${classes["button"]}`}
              onClick={() => {
                setCropping(!cropping);
                setImage(image);
              }}
            >
              {cropping ? "Stop" : "Crop"}
            </button>

            {cropping && (
              <div className="flex items-center gap-4">
                <button
                  disabled={restricted || isLoading}
                  className={`${classes["button"]} ${classes["animation"]}  `}
                  type="button"
                  onClick={dataURLtoFile}
                >
                  {isLoading ? "Saving..." : "Save"}
                </button>
                {restricted && (
                  <p className="flex justify-end text-red-500">
                    Cropped size is too small
                  </p>
                )}{" "}
              </div>
            )}
          </div>
        )}

        <div className="bg-white">
          {isLoading ? (
            <SimpleLoader center />
          ) : (
            <>
              {image && (
                <div
                  className={`relative bg-default p-2 my-8 py-8 flex flex-col justify-center w-full items-center min-h-[100px] max-w-6xl  text-3xl`}
                >
                  {cropping && (
                    <div className="w-full  h-[500px] my-12">
                      <Cropper
                        src={image}
                        backgroundClassName="bg-white"
                        defaultCoordinates={{
                          width: 1200,
                          height: 600,
                        }}
                        stencilProps={{
                          handlers: true,
                          lines: false,
                          movable: false,
                          resizable: true,
                        }}
                        onChange={onChange}
                        imageRestriction={ImageRestriction.stencil}
                      />
                    </div>
                  )}
                  {!cropping && (
                    <>
                      <img
                        src={image}
                        alt={`fetching...`}
                        width={300}
                        height={300}
                      />
                      {selectedOrganisation.showTagLineLogo && (
                        <h3
                          style={{ color: "var(--main-colour)" }}
                          className="text-3xl text-center break-words w-[300px]"
                        >
                          {selectedOrganisation?.tagLine}
                        </h3>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GrowthCircleUploadLogo;
