const TermsContent = () => {
  return (
    <div style={{ color: "var(--text-colour)" }}>
      <h1 className="text-3xl font-bold">Terms & Conditions</h1>
      <h2 className="my-4 font-semibold text-2xl text-start">
        {" "}
        1. THESE TERMS
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          {" "}
          These terms of use govern your use and access to our services,
          including our website(s), our application(s), our application
          programming interfaces (APIs), our notifications and any information
          or content appearing therein (collectively our “Platform”).{" "}
        </li>
        <li className="my-4">
          {" "}
          By using our Platform, you agree to these terms and conditions
          regardless of whether you are a paying user or a non-paying visitor.
          If you are using our Platform as a representative of an entity, you
          are agreeing to these terms on behalf of that entity.{" "}
        </li>
        <li className="my-4">
          {" "}
          You should also read our Privacy Policy which sets out how we collect
          and use your personal information.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        2. ABOUT US AND HOW TO CONTACT US
      </h2>
      <ol className="list-decimal text-start px-5">
        {" "}
        <li className="my-4">
          We are Savoir Asia Consulting Pte Ltd (Growthbeans), a company
          registered in Singapore. Our Unique Entity Number is 201410884W and
          our registered address is at 33 Dairy Farm Road #02-01 S679051.
        </li>
        <li className="my-4">
          For any questions or problems relating to our Platform, our Services
          or these terms, you can email us at growthbeans@savoir-asia.com.
        </li>
        <li className="my-4">
          Please refer to our privacy policy which is available from page 10 of
          this document for information about what personal data we collect and
          what we do with it. It is important that you read our privacy policy,
          which may be updated from time to time, and understand how we use your
          information and the rights that you have about your information.
        </li>
        <li className="my-4">
          If we have to contact you, we will do so by writing to you at the
          email address you provided to us.
        </li>
        <li className="my-4">
          When we use the words “writing” or “written” in these terms, this
          includes emails.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        3. CHANGES OF TERMS
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          1. We may amend these terms from time to time by posting the updated
          terms on our Platform. If we make material changes, we will notify you
          of the changes before they become effective. By continuing to use our
          Platform and our services after the changes come into effect means
          that you agree to be bound by the revised policy.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        4. AVAILABILITY OF OUR SERVICES
      </h2>
      <ol className="list-decimal text-start px-5">
        {" "}
        <li className="my-4">
          We are constantly changing and improving our Platform and the products
          or services we provide. We may from time to time change or discontinue
          any of the products or services we offer, or add or remove
          functionalities or features, and we may suspend or stop certain
          products, services, functionalities or features altogether. If we
          discontinue certain products, services, functionalities or features,
          we will give you advance notice where reasonably possible.
        </li>
        <li className="my-4">
          {" "}
          We may release products, services, functionalities or features that we
          are still testing and evaluating. We will label such services as
          “beta”, “preview”, “early access” or “trial” or any words or phrases
          with similar meanings. You understand that these beta services are not
          as reliable as other products or services we offer.
        </li>
        <li className="my-4">
          We reserve the right to limit your use of our Platform and the
          services we provide, including the right to restrict, suspend or
          terminate your account if we believe you are in breach of these terms
          or are misusing our Platform or any service we provide.
        </li>
        <li className="my-4">
          We try our best to ensure that our Platform is always available, but
          we do not guarantee that the operation of or access to our Platform
          will be uninterrupted or continuous. Our Platform may be interrupted
          for maintenance, repairs, upgrades, network or equipment failures.
        </li>
        <li className="my-4">
          You are responsible for configuring your information technology,
          computer programmes and platform or system in order to access our
          Platform. We do not guarantee that our Platform will be free from bugs
          or viruses.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        5. YOUR ACCOUNT AND PASSWORD
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          In registering for an account on our Platform, you must provide
          truthful, accurate and up-to-date information about yourself. You
          should choose a strong and secure password. You must keep your
          password secure and confidential.
        </li>
        <li className="my-4">
          You agree not to share your account credentials or give others access
          to your account. If and when we detect that an account is shared by
          multiple users, we may treat this as a security breach and suspect or
          terminate your account.
        </li>
        <li className="my-4">
          We have the right to disable any password, whether chosen by you or
          allocated by us, at any time, if in our reasonable opinion you are in
          breach of these terms.
        </li>
        <li className="my-4">
          You agree to follow our Acceptable Use Policy, which is set out at the
          end of these terms.
        </li>
        <li className="my-4">
          You are responsible for all actions or activities that happens by,
          through or under your account, unless you report misuse.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        6. USE OF THE PLATFORM
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          You must comply with the Acceptable Use Policy and all applicable laws
          and regulatory requirements, including privacy laws and intellectual
          property laws in using or accessing the Platform.
        </li>
        <li className="my-4">
          We give you a personal, worldwide, royalty-free, non-assignable,
          non-transferrable, non-sublicensable, non-exclusive and revocable
          license to access and use our Platform, including any software or
          application as part of the services we offer. This license is for the
          sole purpose of enabling you to use and enjoy the benefit of our
          Platform as provided by us and in the manner as permitted by these
          terms.
        </li>
        <li className="my-4">
          The license to use our Platform will terminate if you do not comply
          with these terms or other additional terms or conditions imposed by us
          from time to time.
        </li>
        <li className="my-4">
          You must not copy, modify, distribute, sell, lease, loan or trade any
          access to the Platform or any data or information on it.
        </li>
      </ol>{" "}
      <h2 className="my-4 font-semibold text-2xl text-start">7. YOUR RIGHTS</h2>
      <ol className="list-decimal text-start px-5">
        {" "}
        <li className="my-4">
          You retain your rights to any information or content you submit, post
          or display on or through the Platform (“Your Content”). By submitting,
          posting or displaying such content, you grant us a worldwide,
          non-exclusive, royalty-free license (with the right to sublicense) to
          use, process, copy, reproduce, adapt, modify, publish, transmit,
          display and distribute such content in any and all media or through
          any distribution channels (now known or later developed), subject to
          the applicable provisions in our Privacy Policy.
        </li>
        <li className="my-4">
          You are responsible for your use of Your Content and any consequences
          thereof, including any consequences of the use of Your Content by
          other users or third parties. We are not responsible or liable for any
          use of Your Content, nor the use of any content or information
          submitted or posted by other users or visitors.
        </li>
        <li className="my-4">
          You warrant that Your Content is not and will not infringe rights of
          any third parties and that you have all the necessary rights, power
          and authority to satisfy your obligations with regard to Your Content
          under these terms.
        </li>
        <li className="my-4">
          If you believe your intellectual property rights have been infringed,
          please contact us at growthbeans@savoir-asia.com.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">8. OUR RIGHTS</h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          All intellectual property rights subsisting in the Platform or the
          products or services we provide belong to us or have been lawfully
          licensed to us. All rights under applicable laws are hereby reserved.
          You must not upload, post, publish, reproduce, transmit or distribute
          any content or component of our Platform in any way, or create any
          derivative works with respect to any such content or component.{" "}
        </li>
        <li className="my-4">
          We may (at our discretion but are not obliged to) review content or
          information submitted or posted by users on our Platform. We reserve
          the right to remove any content which we consider as offensive,
          harmful, deceptive, discriminative, defamatory or otherwise
          inappropriate or misleading, or content that we believe may be
          infringing rights of third parties. We do not endorse or support any
          views expressed by any users on our Platform.{" "}
        </li>
        <li className="my-4">
          We reserve the right to review and make any changes to the Booking
          Cancellation policy.{" "}
        </li>
        <li className="my-4">
          Our name “Growthbeans™”, “Growth Circles™” and our marks and logos are
          our trademarks (be it registered or unregistered) and may not be used
          without our express prior written consent.{" "}
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        9. INTEGRATIONS
      </h2>{" "}
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          We integrate with third party software to provide a full suite of
          functionalities to our users. We are not responsible for any issues or
          loss arising from the use of any third party software. Your access and
          use of the third party software is governed by the terms of service or
          user agreements of that software.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">10. FEEDBACK</h2>
      <ol className="list-decimal text-start px-5">
        {" "}
        <li className="my-4">
          We value and welcome feedback on our Platform. You agree that we are
          free to use, disclose, adopt and/or modify any feedback and any
          information (including any ideas, concepts, proposals, suggestions or
          comments) provided by you to use in connection with our Platform or
          any products or services we offer, without any payment to you.{" "}
        </li>
        <li className="my-4">
          You hereby waive and agree to waive any rights to claim for any fees,
          royalties, charges or other payments in relation to our use,
          disclosure, adoption and/or modification of any of your feedback.{" "}
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        11. LIMITATION ON LIABILITIES
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          Some countries or jurisdictions may not allow the disclaimers in this
          clause, in which case these disclaimers will not apply to you.
        </li>
        <li className="my-4">
          To the fullest extent permitted by law, we (including our holding
          company(ies), subsidiaries, affiliates, directors, officers,
          employees, agents, representatives, partners and licensors
          (collectively, “Our Entities”)) expressly limit our liabilities in
          connection with or arising out of the provision of the Platform as
          follows:
        </li>
        <ol className="list-decimal text-start px-5">
          <li className="my-4">
            we provide the Platform and any products or services we offer on an
            ‘“as is” and “as available” basis, and your access to or use of our
            Platform is at your own risk;
          </li>
          <li className="my-4">
            we give no assurance, representation or warranty of any kind
            (whether express or implied) about the Platform and any products or
            services we provide;
          </li>
          <li className="my-4">
            we do not guarantee that the information or content you find on the
            Platform is always accurate, truthful, complete and up-to-date;
          </li>
          <li className="my-4">
            we expressly disclaim all warranties and representations (for
            example, warranties of merchantability, fitness for a particular
            purpose, and non-infringement);
          </li>
          <li className="my-4">
            we are not responsible for any delay or disruption in our Platform
            or any defect, viruses, bugs or errors; and
          </li>
          <li className="my-4">
            we are not responsible for the conduct of or any content or
            information submitted or posted by any user of the Platform (whether
            online or offline).
          </li>
        </ol>
        <li className="my-4">
          To the fullest extent permitted by law, Our Entities are not liable to
          you or others for:
        </li>
        <ol className="list-decimal text-start px-5">
          <li className="my-4">
            any indirect, incidental, special, exemplary, consequential or
            punitive damages; or{" "}
          </li>
          <li className="my-4">
            any loss of data, business, opportunities, reputation, profits or
            revenues,
          </li>
          <li className="my-4">
            {" "}
            relating to the use of our Platform or any products or services we
            offer.{" "}
          </li>
        </ol>
        <li className="my-4">
          We do not exclude or limit our liability to you where it would be
          illegal to do so. This includes any of our liability for fraud or
          making fraudulent misrepresentation in operating the Platform or
          providing the products or services we offer.{" "}
        </li>{" "}
        <li className="my-4">
          {" "}
          5. If you are using the Platform as a consumer, in some countries or
          jurisdictions you may have certain legal rights as a consumer. In such
          cases, nothing in these terms limit your legal rights as a consumer
          that may not be waived by contract.{" "}
        </li>{" "}
        <li className="my-4">
          {" "}
          6. Other than the types of liabilities that we cannot limit by law,
          the liabilities of Our Entities to you (on aggregate) are limited to
          the amount you have paid us (if any) for the use of our Platform or
          for any products or services we offer over the last twelve (12)
          months.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        12. YOUR REPRESENTATION
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          Our Platform is not intended for and may not be used by minors. By
          using our Platform, you represent that you are an adult and that you
          are able to legally enter into contractual agreements.
        </li>
        <li className="my-4">
          If you are using the Platform on behalf of an entity, by using the
          Platform you represent that you have the necessary rights and
          authority to agree to these terms (and our Privacy Policy, Acceptable
          Use policy and other documents referred to herein) on behalf of that
          entity.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">13. INDEMNITY</h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          You agree to indemnify and hold Our Entities harmless from and against
          all liabilities, damages, claims, costs (including legal fees and
          costs), and expenses in connection with or arising from (i) your
          breach of these terms, (ii) your use of our Platform and/or (iii) any
          misrepresentation made by you
        </li>
        <li className="my-4">
          You also agree to fully cooperate with us in the defence or settlement
          of any claim in relation to or arising out of our Platform or these
          terms.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        14. TERMINATION
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          These terms will continue to apply until terminated by either you or
          us as follows.
        </li>
        <li className="my-4">
          You may stop using the Platform any time by deactivating your account.
        </li>
        <li className="my-4">
          We reserve the right to suspend or terminate your access to our
          Platform, if we reasonably believe:
        </li>
        <ol className="list-decimal text-start px-5">
          <li className="my-4">
            you are in serious or repeated breach of these terms (including a
            prolonged failure to settle any payment);
          </li>
          <li className="my-4">
            you are using the Platform in a manner that would cause a real risk
            of harm or loss to us, other users, or the public;
          </li>
          <li className="my-4">
            we are requested to do so by government or regulatory authorities or
            as required under applicable laws, regulations or legal processes;
            or
          </li>
          <li className="my-4">
            our provision of the Platform to you is no longer possible or
            commercially viable.
          </li>
        </ol>
      </ol>
      <p className="py-4 text-start">
        In any of the above cases, we will notify you by the email address
        associated with your account or at the next time you attempt to access
        your account, unless we are prohibited from notifying you by law.
      </p>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          Upon termination of your access, these terms will also terminate
          except for Clause 11 to 18.
        </li>
        <li className="my-4">
          Where we consider necessary or appropriate, we will report any breach
          of these terms (or the Acceptable Use Policy) to law enforcement
          authorities and we will cooperate with such authorities by disclosing
          your identity and providing any information about you within our
          systems to them.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        15. ENTIRE AGREEMENT
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          These terms constitute the entire agreement between any user and us in
          relation to the user of or any transactions on the Platform. These
          terms supersede and extinguish all other agreements, promises,
          assurances, warranties, representations and understandings between any
          user and us, whether written or oral, in relation to the use of or any
          transactions on the Platform.
        </li>
        <li className="my-4">
          You acknowledge that you will have no remedies in respect of any
          statement, representation, assurance or warranty (whether made
          innocently or negligently) that is not set out in these terms.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        16. OTHER IMPORTANT TERMS
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          We may transfer our rights and obligations under these terms to
          another organization. We will contact you to let you know if we plan
          to do this. If you do not wish to continue the contract with the
          transferee, you may contact us to end the contract within one (1)
          calendar month of us informing you of the proposed transfer and we
          will refund you any payments you have made in advance for any products
          or services not provided.
        </li>
        <li className="my-4">
          You may only transfer your rights or your obligations under these
          terms to another person if we agree to this in writing.
        </li>
        <li className="my-4">
          If a court finds part of this contract illegal, the rest will continue
          in force. Each of the paragraphs of these terms operates separately.
          If any court or relevant authority decides that any of them are
          unlawful, the remaining paragraphs will remain in full force and
          effect.
        </li>
        <li className="my-4">
          Even if we delay in enforcing this contract, we can still enforce it
          later. If we do not insist immediately that you do anything you are
          required to do under these terms, or if we delay in taking steps
          against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent
          us taking steps against you at a later date. For example, if you miss
          a payment and we do not chase you but we continue to provide the
          products, we can still require you to make the payment at a later
          date.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">17. CONTACT</h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          If you have any questions about these terms or the Acceptable Use
          Policy, please contact us at growthbeans@savoir-asia.com.
        </li>
      </ol>
      <h2 className="my-4 font-semibold text-2xl text-start">
        18. GOVERNING LAW AND JURISDICTION
      </h2>
      <ol className="list-decimal text-start px-5">
        <li className="my-4">
          These terms are governed by and shall be construed in accordance with
          the laws of the Republic of Singapore.
        </li>
        <li className="my-4">
          The courts of the Republic of Singapore shall have exclusive
          jurisdiction to settle any dispute or claim arising out of or in
          connection with these terms.
        </li>
      </ol>
      <h1 className="text-3xl font-bold">ACCEPTABLE USE POLICY</h1>
      <div className="py-4">
        <p className="text-start">
          As part of the terms of use, you agree not to misuse the Platform or
          help anyone else to do so. For example, you agree not to do any of the
          following in connection with the Platform:
        </p>
        <ul className="text-start px-8 py-4">
          <li className="py-2">
            (a) use our Platform for unlawful or unauthorized purposes;
          </li>
          <li className="py-2">
            (b)re-sell or attempt to benefit in a commercial fashion from any
            data, content or information available on the Platform;
          </li>
          <li className="py-2">
            (c) probe, scan, or test the vulnerability of any system or network;
          </li>
          <li className="py-2">
            (d) breach or otherwise circumvent any security or authentication
            measures or service use limits;
          </li>
          <li className="py-2">
            (e) access, tamper with, or use non-public areas or parts of the
            Platform;
          </li>
          <li className="py-2">
            (f) interfere with or disrupt any user, host, or network, for
            example by sending a virus, trojan, worm, logic bomb, or any other
            material that is malicious or technologically harmful, overloading,
            flooding, spamming, or mail-bombing any part of the Platform, or by
            scripting the creation of any content in such manner as to interfere
            with or create an undue burden on the Platform;
          </li>
          <li className="py-2">
            (g) reverse engineer, decompile, disassemble, decipher or otherwise
            attempt to derive the source code for the Platform or any related
            technology that is not open source;
          </li>
          <li className="py-2">
            (h) access, search, or create accounts for the Platform by any means
            (automated or otherwise) other than our publicly supported
            interfaces (for example, “scraping” or creating accounts in bulk) or
            attempt to do so;
          </li>
          <li className="py-2">
            (i) send unsolicited communications, promotions or advertisements,
            or spam;
          </li>
          <li className="py-2">
            (j) forge any TCP/IP packet header or any part of the header
            information in any email;
          </li>
          <li className="py-2">
            (k) send altered, deceptive, or false source-identifying
            information, including “spoofing” or “phishing”;
          </li>
          <li className="py-2">
            (l) conduct surveys, contests, or pyramid schemes, or promote or
            advertise products or services without appropriate authorization;
          </li>
          <li className="py-2">(m) abuse referrals or promotions;</li>
          <li className="py-2">
            (n) post, publish, upload, display, distribute, or share materials
            that are unlawful, inappropriate, profane, pornographic, obscene,
            indecent, libelous, defamatory, abusive, or knowingly false, and/or
            that infringe intellectual property rights;
          </li>
          <li className="py-2">
            (o) violate the letter or spirit of our terms of us;
          </li>
          <li className="py-2">
            (p) violate applicable laws or regulations in any way; or
          </li>
          <li className="py-2">
            (q) violate the privacy or infringe the rights of others.
          </li>
        </ul>
        <p className="text-start">Last updated: 1 September 2023</p>
      </div>
      <h1 className="text-3xl font-bold">Data Privacy Policy</h1>
      <div className="py-8 text-start">
        <p className="py-4">
          This policy sets out the basis on which Savoir Asia Consulting Pte Ltd
          (Growthbeans) of 33 Dairy Farm Road #02-01 Singapore 679051 (Unique
          Entity Number 20141088W) (together with our subsidiaries, our holding
          company, subsidiaries of our holding company from time to time,
          collectively called “Growthbeans” or “we”) collects personal data from
          you and how we process such data.{" "}
        </p>
        <p className="py-4">
          By visiting our website https://growthcircles-webapp.com (our “Site”)
          or using Growthbeans or any other applications or software we provide
          from time to time (collectively our “App”), you accept and consent to
          the practices set out below.
        </p>
        <h2 className="text-2xl font-bold py-4">
          1. Collection of Information
        </h2>
        <p className="py-2">
          For the purposes outlined in Clause 2, we may collect and process the
          following information about you:
        </p>
        <ol className="list-inside list-disc">
          <li>
            <i>Information you provide us with –</i> information that you
            provide us (which may include your name, address, email address,
            telephone number, credit card information and other personal
            description) by filling in forms on our Site or our App, or by
            corresponding with us (by phone, email or otherwise), for example:
          </li>
        </ol>
        <ul className="list-inside list-decimal px-5 py-4">
          <li>
            when you register for an account with us on our Site or our App;
          </li>
          <li>when you report any issue to us;</li>
          <li>when you use certain features on our Site or our App;</li>
          <li>when you request any support from us; or</li>
          <li>when you complete any survey or questionnaire we send you.</li>
        </ul>
        <ol className="list-inside list-disc">
          <li>
            <i>Information we collect about you –</i> information automatically
            collected when you visit our Site or use our App, for example:
          </li>
        </ol>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            Technical information, including the Internet Protocol (IP) address
            used to connect your computer to the Internet and your log-in
            information, browser type and version, time zone setting, browser
            plug-in types and versions, operating system and platform;{" "}
          </li>
          <li className="my-4">
            Information about your visit, including the full Uniform Resource
            Locators (URLs), clickstream to, through and from out Site
            (including date and time), products you viewed and searched for,
            page response times, download errors, length of visits to certain
            pages, page interaction information (such as scrolling, clicks, and
            mouse-overs), methods used to browse away from the page, and any
            phone number used to call our customer service number; and
          </li>
        </ul>
        <ol className="list-inside list-disc">
          <li>
            <i>Information we receive from third parties –</i> We work with
            third parties and we may receive information about you from them,
            for example, business partners, sub-contractors in technical,
            payment and delivery services, advertising networks, analytics
            providers, search information providers, or credit reference
            agencies. We will notify you when we receive information about you
            from them and the purposes for which we intend to use that
            information
          </li>
        </ol>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            We only retain personal data for as long as it is necessary. Data
            may be archived as long as the purpose for which the data was used
            still exists.{" "}
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-4">2. Use of Information</h2>
        <p className="p-4">
          We use information held about you for the following purposes:
        </p>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            providing, improving and developing our services;
          </li>
          <li className="my-4">
            researching, designing and launching new features or products;
          </li>
          <li className="my-4">
            presenting content and information on our Site and our App in the
            most effective manner for you and for the device you use;
          </li>
          <li className="my-4">
            providing you with alerts, updates, materials or information about
            our services or other types of information that you requested or
            signed up to;
          </li>
          <li className="my-4">collecting overdue amounts;</li>
          <li className="my-4">
            complying with laws and regulations applicable to us in or outside
            of Asia;
          </li>
          <li className="my-4">
            responding or taking part in legal proceedings, including seeking
            professional advice;
          </li>
          <li className="my-4">
            for direct marketing purposes (please see further details in Clause
            3 below);
          </li>
          <li className="my-4">
            communicating with you and responding to your questions or requests;
            and
          </li>
          <li className="my-4">
            purposes directly related or incidental to the above.
          </li>
          <li className="my-4">
            We intend to use your personal data in direct marketing, i.e.
            offering or advertising products or services by sending the relevant
            information directly to you). We require your consent specifically
            for this purpose and you may opt out at any time. For the purpose of
            this clause:
          </li>
          <ul className="list-inside list-decimal px-5 py-2">
            <li className="my-4">
              the personal data that may be used in direct marketing are those
              that you provide to us or we collect from you under Clause 1
              above;
            </li>
            <li className="my-4">
              the type of services or products that may be offered or advertised
              will be growth circle experiences, newsletters to improve
              wellbeing and people skills, social community support, and those
              of our affiliates or business partners;
            </li>
            <li className="my-4">
              the relevant information may be sent to you via email, in-app
              notification, social media and/or digital advertising;{" "}
            </li>
            <li className="my-4">
              you may opt out at any time by emailing us
              growthbeans@savoir-asia.com. We will cease to send you marketing
              information without charge.{" "}
            </li>
          </ul>
        </ul>
        <h2 className="text-2xl font-bold py-4">
          3. Disclosure of Information
        </h2>
        <p className="py-2">
          We will keep your personal data that we hold confidential, however you
          consent that we may provide information to:
        </p>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            any member of our group, which means our subsidiaries, our ultimate
            holding company and its subsidiaries, as defined in Section 5 of the
            Companies Act (Chapter 50);
          </li>
          <li className="my-4">
            personnel, agents, advisers, auditors, contractors, financial
            institutions, and service providers in connection with our
            operations or services (for example, staff engaged in the fulfilment
            of your order, the processing or your payment and the provision of
            support services);
          </li>
          <li className="my-4">
            our overseas offices, affiliates, business partners and counterparts
            (on a need-to-know basis only);
          </li>
          <li className="my-4">
            persons under a duty of confidentiality to us;
          </li>
          <li className="my-4">
            persons to whom we are required to make disclosures under applicable
            laws and regulations in or outside of Asia; or
          </li>
          <li className="my-4">
            actual or proposed transferees of our operations (or a substantial
            part thereof) in or outside of Asia.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-4">4. Cookies</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            Our Site uses cookies to distinguish you from other users of the
            Site. This helps us to provide you with a good experience when you
            browse our Site and also allows us to improve our Site.
          </li>
          <li className="my-4">
            A cookie is a small file of letters and numbers that we store on
            your browser or the hard drive of your computer if you agree to the
            use of cookies. Cookies contain information that is transferred to
            your computer’s hard drive.
          </li>
          <li className="my-4">
            We use persistent cookies and session cookies. A persistent cookie
            stays in your browser and will be read by us when you return to our
            Site or a partner site that uses our services. Session cookies only
            last for as long as the session (usually the current visit to a
            website or a browser session).
          </li>
          <li className="my-4">We use the following cookies:</li>
          <ul className="list-inside list-decimal px-5 py-2">
            <li className="my-4">
              {" "}
              <i>Strictly necessary cookies –</i> These are cookies that are
              required for the operation of our Site. They include, for example,
              cookies that enable you to log into secure areas of our Site, use
              a shopping cart or make use of e-billing services.
            </li>
            <li className="my-4">
              {" "}
              <i>Analytical/performance cookies –</i> They allow us to recognise
              and count the number of visitors and to see how visitors move
              around our Site when they are using it. This helps us improve the
              way our Site works, for example, by ensuring that users are able
              to easily find what they are looking for.
            </li>
            <li className="my-4">
              {" "}
              <i>Functionality cookies –</i> These are used to recognise you
              when you return to our Site. This enables us to personalize our
              content for you, greet you by name and remember your preferences,
              for example, your choice of language or region.
            </li>
            <li className="my-4">
              {" "}
              <i>Targeting cookies –</i> These cookies record your visit to our
              Site, the pages you have visited and the links you have followed.
              We will use this information to make our Site and the information
              displayed on it more relevant to your interests.
            </li>
            <li className="my-4">
              {" "}
              You can block cookies by activating the setting on your browser
              that allows you to refuse the setting of all or some cookies.
              However, if you do so, you may not be able to access all or parts
              of our Site.
            </li>
            <li className="my-4">
              {" "}
              We may use third party web services on our Site. The service
              providers that administer these services use technologies such as
              cookies, web server logs and web beacons to help us analyse how
              visitors use our Site and make the information displayed on it
              more relevant to your interests. The information collected through
              these means (including IP addresses) may be disclosed to these
              service providers. These analytics services may use the data
              collected to contextualise and personalise the marketing materials
              of their own advertising network.
            </li>
          </ul>
        </ul>
        <h2 className="text-2xl font-bold py-4">5. Third Party Sites</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            {" "}
            Our Site, our App or our communications with you may, from time to
            time, contain links to third party websites over which we have no
            control. If you follow a link to any of these websites, please note
            that they have their own practices and policies. We encourage you to
            read the privacy policies or statements of these websites to
            understand your rights. We accept no responsibility or liability for
            any practices of third party websites.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-4">6. Security</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            All information you provide to us is stored on our secure servers.
          </li>
          <li className="my-4">
            Any payment transactions will be encrypted using TLS/SSL technology.
          </li>
          <li className="my-4">
            Where we have given you (or where you have chosen) a password that
            enables you to access certain parts of the Site, you are responsible
            for keeping this password confidential. We ask you not to share your
            passwords with anyone.
          </li>
          <li className="my-4">
            We restrict access to personal information to our employees, service
            providers and contractors on a strictly need-to-know basis and
            ensure that those persons are subject to contractual confidentiality
            obligations.
          </li>
          <li className="my-4">
            We review our information collection, storage and processing
            practices from time to time to guard against unauthorized access,
            processing or use.
          </li>
          <li className="my-4">
            Please note, however, the transmission of information via the
            Internet is not completely secure. Although we will do our best to
            protect your personal data, we cannot guarantee the security of your
            data transmitted to our Site; any transmission is at your own risk.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-2">7. Data Transfer</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            {" "}
            The data that we collect from you may be transferred to, and stored
            at, a destination outside Singapore. It may also be processed by
            persons referred to in Clause 3 above who operate outside Singapore.
          </li>
          <li className="my-4">
            By submitting your personal data, you agree to this transfer,
            storing or processing of data outside Singapore. We will take all
            steps reasonably necessary to ensure that your data is treated
            securely in accordance with this privacy policy.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-2">8. Your Rights</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-2">You have the right to:</li>
          <ul className="list-inside list-decimal px-5 ">
            <li className="my-4">
              check whether we hold personal data about you;
            </li>
            <li className="my-4">
              access any personal data we hold about you; and
            </li>
            <li className="my-4">
              require us to correct any inaccuracies or errors in any personal
              data we hold about you.
            </li>
          </ul>
          <li className="my-4">
            Any request under Clause 8.1 may be subject to a small
            administrative fee to cover our cost in processing your request.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-2">
          9. Changes to Our Privacy Policy
        </h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-4">
            We may amend this policy from time to time by posting the updated
            policy on our Site. By continuing to use our Site and our App after
            the changes come into effect means that you agree to be bound by the
            revised policy.
          </li>
        </ul>
        <h2 className="text-2xl font-bold py-2">10. Contact Us</h2>
        <ul className="list-inside list-decimal px-5 py-2">
          <li className="my-2">
            If you have any questions, comments or requests regarding personal
            data, please address them to:
          </li>
          <table>
            <thead>
              <tr>
                <th className="text-start">Name:</th>
                <td className="px-2">Dylan Liau</td>
              </tr>
              <tr>
                <th className="text-start">Title:</th>
                <td className="px-2">Data Protection Officer</td>
              </tr>
              <tr>
                <th className="text-start">Email:</th>
                <td className="px-2">growthbeans@savoir-asia.com</td>
              </tr>
            </thead>
          </table>
        </ul>
        <p className="py-2 text-start">Last updated: 1 September 2023</p>
      </div>
    </div>
  );
};

export default TermsContent;
