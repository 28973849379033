import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRanger } from "../Slider/SliderFunction";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import classes from "./UserBarHandle.module.css";
import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import {
  PartialParticipant,
  Participant,
} from "interface/ParticipantInterface";
import useParticipants from "hooks/useParticipants";

interface UserBarHandleProps {
  participants: (Participant | PartialParticipant)[];
  values: number[];
  currentCheckInType: string | null;
  checkInType: string;
  setCurrentCheckInType: Dispatch<SetStateAction<string | null>>;
  max: number;
}

/**
 * User bar used in RatingAverage and EndRatingAverage page (so far) that is a horizontal line with colours based on the different participants that chosen that rating
 * @param {Participant[]} participants - Participants with this rating
 * @param {number[]} values - rating (from [0] to [10])
 * @param {string | null} currentCheckInType - current one user is focused on (Individually, Interpersonally, Socially, Overall)
 * @param {string} checkInType - the checkIn type of this component (Individually, Interpersonally, Socially, Overall)
 * @param {Dispatch<SetStateAction<string | null>>} setCurrentCheckInType - method to set checkInType to this component
 */
const UserBarHandle = ({
  participants,
  values,
  checkInType,
  currentCheckInType,
  setCurrentCheckInType,
  max,
}: UserBarHandleProps) => {
  const { getParticipantColourIndex, participants: realtimeParticipants } =
    useGetParticipants();
  const { profile } = useAuthContext();
  const [handleStyle, setHandleStyle] = useState<React.CSSProperties>({});
  const [showContent, setShowContent] = useState(false);
  const { sessionRole } = useParticipants();

  const getHandleStyle = () => {
    //Dynamically generate linear gradient
    const interval = +(100 / participants.length).toFixed(1);
    let currPercentage = 0;
    let linearGradient = "linear-gradient(to bottom,";

    participants.forEach((participant, index) => {
      //Append to linearGradient its colour
      //Get colour index
      const colourIdx = getParticipantColourIndex(participant.userId);
      // console.log(colourIdx);

      linearGradient +=
        " var(--icon-colour-" +
        colourIdx +
        ") " +
        Math.round(currPercentage) +
        "%,";
      currPercentage += interval;

      linearGradient +=
        " var(--icon-colour-" +
        colourIdx +
        ") " +
        Math.round(currPercentage) +
        "%";
      if (participants.length - 1 !== index) {
        linearGradient += ",";
      } else {
        linearGradient += ")";
      }
    });

    const handleStyle: React.CSSProperties = {
      background: linearGradient,
    };

    setHandleStyle(handleStyle);
    // return handleStyle;
  };

  useEffect(() => {
    if (!!realtimeParticipants) {
      getHandleStyle();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeParticipants, profile]);

  useEffect(() => {
    getHandleStyle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  useEffect(() => {
    // console.log(Object.keys(handleStyle).length);
    if (Object.keys(handleStyle).length > 0) {
      if (sessionRole?.name === "host") {
        setShowContent(true);
      } else {
        const _new = participants.filter((p) => p.userId === profile?.uid);
        if (_new.length > 0) {
          setShowContent(true);
        } else {
          setShowContent(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [handleStyle]);

  const setCurrentCheckInTypeHandler = () => {
    if (checkInType === currentCheckInType) {
      setCurrentCheckInType(null);
    } else {
      setCurrentCheckInType(checkInType);
    }
  };

  const { staticHandles } = useRanger({
    min: 1,
    max: max,
    stepSize: 1,
    values,
    onChange: () => {},
  });

  return (
    <>
      {showContent &&
        staticHandles.map(({ value, active, getHandleProps }) => (
          <button
            onClick={() => setCurrentCheckInTypeHandler()}
            type="button"
            key={value}
            {...getHandleProps({
              style: {
                appearance: "none",
                border: "none",
                background: "transparent",
                outline: "none",
              },
            })}
            className={`${classes["bar-button"]}  `}
          >
            {/* An array of handles */}
            <div
              className={`${classes["avatars"]} ${
                currentCheckInType !== checkInType &&
                realtimeParticipants.some(
                  (_participant) => _participant.userId === profile?.uid
                )
                  ? classes["avatars--hidden"]
                  : ""
              }`}
            >
              {realtimeParticipants.some(
                (_participant) => _participant.userId === profile?.uid
              ) &&
                participants.map((participant, idx) => {
                  return (
                    <FirstNameAvatar
                      key={idx}
                      name={
                        participant.userId === profile?.id
                          ? "ME"
                          : participant.userName
                      }
                      participant={participant}
                      participantNumber={getParticipantColourIndex(
                        participant.userId
                      )}
                      customStyle={"rating"}
                    />
                  );
                })}
            </div>
            {realtimeParticipants.some(
              (_participant) => _participant.userId === profile?.uid
            ) && (
              <div
                className={`${classes["bar"]}`}
                data-active={active.toString()}
                style={handleStyle}
              ></div>
            )}

            <div
              className={`${classes["avatars"]} ${classes["avatars--hidden"]}`}
            >
              {participants.map((participant, idx) => {
                return (
                  <FirstNameAvatar
                    key={idx}
                    name={participant.userName}
                    participantNumber={getParticipantColourIndex(
                      participant.userId
                    )}
                    participant={participant}
                    customStyle={"rating"}
                  />
                );
              })}
            </div>
            {/* <div className={`${classes["handle"]}`} data-active={active.toString()}>
            {rating} {values}
          </div> */}
          </button>
        ))}
    </>
  );
};

export default UserBarHandle;
