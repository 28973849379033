import { useCallback, useEffect, useState } from "react";
import NavTree from "assets/NavTree";
import { useAuthContext } from "hooks/useAuthContext";
import useUsers from "hooks/useUsers";
import { EmailAuthProvider } from "firebase/auth";
import classes from "./Navigation.module.css";
import ChangePasswordPrompt from "./ChangePasswordPrompt";

const Navigation = () => {
  const { profile } = useAuthContext();
  const { fetchSignInMethods } = useUsers();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clickHandler = (e: void) => {};

  /**
   * Checks whether the user should be prompted to change password.
   * All new users are required to have strong password. This function checks for users that
   * signed up before the mandatory password strength checks.
   *
   * @returns True if user can only sign in with username and password
   *    and does not have a strong password.
   */
  const shouldPromptChangePassword = useCallback(async () => {
    if (!profile) {
      return false;
    }
    return fetchSignInMethods(profile.email).then((signInMethods) => {
      const onlyOneSignInMethod = signInMethods.length === 1;
      const isEmailPasswordSignIn = signInMethods.includes(
        EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      );
      const hasWeakPassWord = !profile.hasStrongPassword;
      return onlyOneSignInMethod && isEmailPasswordSignIn && hasWeakPassWord;
    });
  }, [fetchSignInMethods, profile]);

  useEffect(() => {
    shouldPromptChangePassword().then((shouldPrompt) => {
      if (shouldPrompt) {
        setIsModalOpen(true);
      }
    });
  }, [shouldPromptChangePassword]);

  const WelcomeMessage = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    let greeting;

    if (currentHour < 12) {
      greeting = "Good morning!";
    } else if (currentHour < 18) {
      greeting = "Good afternoon!";
    } else {
      greeting = "Good evening!";
    }

    return (
      <h2
        className={`${classes.greetings} text-lg md:text-3xl`}
      >{`${greeting}`}</h2>
    );
  };

  return (
    <div className={classes["navigation-page"]}>
      <div className={classes["navtree-placeholder"]}>
        <ChangePasswordPrompt
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        {WelcomeMessage()}
        <div className="text-2xl font-medium w-full max-w-[500px] mx-auto">
          <p className="text-[var(--text-colour)] px-2 mx-auto md:mx-0 w-56 text-left text-ellipsis overflow-hidden text-lg md:text-3xl md:my-4">
            {profile?.displayName}
          </p>
        </div>
      </div>
      {/* 145 -5 90 440 */}

      <NavTree
        viewBox={window.innerWidth < 768 ? "155 -55 90 550" : "145 -5 90 440"}
        onClick={clickHandler}
      />

      <div className={`${classes["mascot-content"]} flex`}>
        {/* <ScrollTip viewBox="40 0 100 100" /> */}
        {/* <div className="max-w-1/2 align-center">
          <Mascot viewBox="0 0 200 180" />
        </div> */}
      </div>
    </div>
  );
};

export default Navigation;
