import RectangularBubble from "components/utility/RectangularBubble";
import { FC, MouseEvent, useEffect, useState } from "react";

import ContentTypes from "components/GroundRules/ContentTypes";
import LarefSessionTemplate from "components/utility/SessionTemplate/LarefSessionTemplate";
import { useAuthContext } from "hooks/useAuthContext";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { updateWindowLocationPath } from "models/profile";
import Acknowledgements from "components/SUSS/Acknowledgement";
import {
  ComponentProps,
  getContentByHeaderTitle,
  getProperNameComponentHeader,
} from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import ExpectRow from "components/GroundRules/ExpectRow";

/**
 * Page that shows the ground rules for users when they first start a GC session
 */
const GroundRulesComponent: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const [contentType, setContentType] = useState("Mindful");
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const handleNext = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    currentContentType: string
  ) => {
    switch (currentContentType) {
      case "Mindful":
        setContentType("Invitation");
        break;
      case "Invitation":
        setContentType("Expect");
        break;
      case "Expect":
        backAndNextHandler.nextCallback(e);
        break;
    }
    if (!profile?.uid) {
      return;
    }
    updateWindowLocationPath(profile.uid);
  };

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  useEffect(() => {
    if (!growthCircleSession) return;
  }, [growthCircleSession]);

  const displayedHeaders = new Set();
  let numberInx = 0;
  const componentsDisplay = selectedOrganisation?.components.map(
    (comp, index) => {
      const properName = getProperNameComponentHeader(comp);

      if (!displayedHeaders.has(properName)) {
        displayedHeaders.add(properName);
        numberInx += 1;
        let _modName: string;
        if (
          properName === "Reflection on Session" ||
          properName === "Reflection on GrowthCircles"
        ) {
          _modName = "Reflection";
        } else {
          _modName = properName;
        }
        const content = getContentByHeaderTitle(_modName);
        return (
          <ExpectRow
            key={index}
            title={`${numberInx}: ${_modName}`}
            content={content}
          />
        );
      }
      return null; // Skip rendering if headerText is already displayed
    }
  );

  // <p
  //   key={index}
  //   className="p-5 bg-default text-standard rounded-3xl shadow-md"
  // >
  //   {numberInx}. {properName}
  // </p>
  return (
    <>
      <Acknowledgements />
      <div className="relative">
        <LarefSessionTemplate
          hasModal={true}
          stepperContent={ContentTypes[contentType].stepperContent}
        >
          <RectangularBubble
            key={ContentTypes[contentType].id}
            id={ContentTypes[contentType].id}
            title={ContentTypes[contentType].title}
            subtitle={ContentTypes[contentType].subtitle}
            buttonText={ContentTypes[contentType].buttonText}
            buttonMethod={(e) => handleNext(e, contentType)}
          >
            {contentType !== "Expect" && ContentTypes[contentType].content}
            {contentType === "Expect" && componentsDisplay}
          </RectangularBubble>
        </LarefSessionTemplate>
        <NGFooter type={growthCircleSession?.type} />
      </div>
    </>
  );
};

export default GroundRulesComponent;
