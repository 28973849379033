import FiveStepHeader from "components/FiveStepHeader/FiveStepHeader";
import Header from "components/Header/Header";
import BackAndNext from "components/utility/BackAndNext";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import Steps from "components/utility/Steps";
import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import useParticipants from "hooks/useParticipants";
import NoGCSessionWrapper from "pages/NoGCSession/NoGCSessionWrapper";
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { ACTIVITY_INSTRUCTIONS } from "strings/Instructions";
import {
  NG_CHOOSE_ACTIVITY,
  NG_REFLECTION_ACTIVITY_ROUTE,
} from "strings/Routes";
import { ReactComponent as CloseIcon } from "assets/Xbutton.svg";
import { ReactComponent as InfoIcon } from "assets/NG/Activity/info-icon.svg";
import debounce from "lodash/debounce";

import { useGCSession } from "hooks/useGCSession";
import { Toaster } from "react-hot-toast";
import { Activity } from "interface/ActivityInterface";
import NGFooter from "components/Footers/NGFooter";
import { checkCanAdvanceNextActivity } from "models/organisationRole";

const UploadImage = lazy(() => import("components/UploadImage/UploadImage"));

const ActivityPage = () => {
  const currentStep = 4;
  const stepsCount = 5;

  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const [isVisible, setIsVisible] = useState(false);
  const [activity, setActivity] = useState<Activity>(Object);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasLightBox, setHasLightBox] = useState(false);
  const [proceedDelete, setProceedDelete] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [participantsIsLoaded, setParticipantsIsLoaded] = useState(false);
  const { sessionRole, ParticipantRecord, updateActivityTitle } =
    useParticipants();
  const { facilAllowNextPage } = useGCSession();
  const [hasTrigger, setHasTrigger] = useState(false);
  const _setTrigger = () => {
    setHasTrigger(!hasTrigger);
  };

  const canAdvance = checkCanAdvanceNextActivity(sessionRole);

  const navigate = useNavigate();
  useEffect(() => {
    if (!profile) return;
    if (!growthCircleSession) return;
    if (!ParticipantRecord[0]) return;
    setParticipantsIsLoaded(true);
    let obj = getActivityByActivityName(growthCircleSession.facilActivity);
    if (obj) {
      setActivity(obj);
    }
  }, [profile, growthCircleSession, activity, ParticipantRecord]);

  useEffect(() => {
    if (!participantsIsLoaded) return;

    if (ParticipantRecord[0]?.activityTitle) {
      setInputValue(ParticipantRecord[0].activityTitle);
    }

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsIsLoaded, inputFileRef, isVisible]);

  const debouncedUpdate = debounce((value: string) => {
    if (value) {
      updateActivityTitle(value);
    }
  }, 1000); // 1000 ms debounce delay

  const modalHandler = () => {
    setIsVisible(!isVisible);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputValue(e.target.value);
    debouncedUpdate(inputValue);
  };

  const deleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const getActivityByActivityName = (activityName) => {
    const activityObj = ACTIVITY_INSTRUCTIONS.find(
      (activity) => activity.title === activityName
    );

    return activityObj;
  };

  const setLightBoxHandler = () => {
    setHasLightBox(!hasLightBox);
  };

  const confirmDelete = () => {
    handleConfirmDelete();
    setProceedDelete(true);
    const timeout = setTimeout(() => {
      setProceedDelete(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    return () => {
      clearTimeout(timeout);
    };
  };

  const nextHandler = () => {
    if (canAdvance) {
      facilAllowNextPage("add-activity");
      navigate(NG_REFLECTION_ACTIVITY_ROUTE);
    } else {
      navigate(NG_REFLECTION_ACTIVITY_ROUTE);
    }
  };

  const backHandler = () => {
    navigate(NG_CHOOSE_ACTIVITY);
  };

  return (
    <NoGCSessionWrapper>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="font-semibold mt-5 text-lg text-slate-700">
                Instructions
              </h3>
              <EmptySpace height={"20px"} />
              <div className="text-slate-700 text-left">
                {activity &&
                  activity.instructions !== undefined &&
                  activity.instructions.map(
                    (instruction: string, index: number) => {
                      return (
                        <div
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: `${index + 1}. ${instruction}`,
                          }}
                        ></div>
                      );
                    }
                  )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          show={showDeleteModal}
          size="md"
          className="min-h-screen"
          popup={true}
        >
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]  ">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon
                className="cursor-pointer"
                onClick={deleteModalHandler}
              />
            </div>
            <div className="text-center py-8  ">
              <h4 className="font-semibold mt-5 text-lg text-slate-700">
                You are about to delete this picture. Do you wish to proceed?
              </h4>
              <EmptySpace height={"20px"} />
              <div className="flex justify-center gap-4 mt-5">
                <button
                  type="button"
                  className="shadow-md bg-facil-colour px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000"
                  onClick={confirmDelete}
                >
                  <span>{"Yes"}</span>
                </button>
                <button
                  type="button"
                  className="shadow-md bg-facil-colour px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000"
                  onClick={deleteModalHandler}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>

      <Header
        hasModal={isVisible || showDeleteModal || hasLightBox || hasTrigger}
      />
      <FiveStepHeader
        hasModal={isVisible || showDeleteModal || hasLightBox || hasTrigger}
        hasLightning={true}
        hasGuide={false}
        currentStep={currentStep}
        setIsTriggerVisible={_setTrigger}
        text="Choose & Share"
      />
      <Toaster
        position="top-center"
        containerStyle={{
          top: "15%",
        }}
        toastOptions={{
          style: {
            backgroundColor: "white",
            color: "var(--text-colour)",
            textAlign: "center",
          },
        }}
      />
      <ContentWrapper hasFiveStepper={true}>
        <Center>
          <EmptySpace height={"5px"} />
          <Steps count={stepsCount} currentStep={currentStep} />
          {growthCircleSession && activity && (
            <div className="mx-5">
              <div className="py-6 text-center flex flex-col justify-center items-center">
                <div className="relative w-full">
                  <h3 className="font-bold text-xl">
                    <span
                      className={`block ${
                        profile?.isFacil
                          ? "text-facil-colour"
                          : "text-main-colour"
                      }`}
                    >
                      {growthCircleSession?.facilActivity}
                    </span>
                  </h3>
                  <div className="absolute right-[10%] md:right-[20%] top-0">
                    <InfoIcon
                      className="cursor-pointer absolute right-0 top-0"
                      onClick={() => modalHandler()}
                    />
                  </div>
                </div>
                <p className="text-sm mt-5">
                  Click on the ‘i’ icon to view the instructions.
                </p>
                <div className="mt-5 self-center text-center"></div>
              </div>
              {/* upload */}
              <Suspense fallback={<div>Loading...</div>}>
                <UploadImage
                  activity={activity}
                  hasLightBox={hasLightBox}
                  setHasLightBox={setLightBoxHandler}
                  deleteEvent={deleteModalHandler}
                  confirmDelete={proceedDelete}
                />
              </Suspense>
              <EmptySpace height={"10px"} />
              {activity && (
                <div className="bg-white shadow-md rounded-lg w-full  p-5">
                  <p className="text-left text-base text-slate-500">
                    {activity.inputTitle}
                  </p>
                  <EmptySpace height={"10px"} />
                  <input
                    defaultValue={ParticipantRecord[0]?.activityTitle}
                    ref={inputFileRef}
                    onChange={onInputChange}
                    onBlur={onInputChange}
                    type="text"
                    name="title"
                    className={`w-full shadow-md border border-slate-400/[.1] rounded-lg  pl-7 ${
                      profile?.isFacil
                        ? "placeholder-facil-colour"
                        : "placeholder-main-colour"
                    } `}
                    placeholder={activity.placeholder}
                  />
                </div>
              )}
            </div>
          )}
          {!activity && (
            <h3 className="w-64 mt-10 text-center">
              Facilitator is changing the activity please wait
            </h3>
          )}
          <EmptySpace height={"40px"} />

          {activity && (
            <BackAndNext
              nextAllowed={
                (canAdvance ||
                  (growthCircleSession?.allowAddActivity &&
                    ParticipantRecord[0]?.activityTitle !== undefined &&
                    ParticipantRecord[0]?.activityTitle !== "")) &&
                inputValue !== ""
              }
              nextCallback={nextHandler}
              backAllowed={true}
              backCallback={backHandler}
              backHidden={undefined}
            />
          )}

          <EmptySpace height={"60px"} />
          <NGFooter type={growthCircleSession?.type} />
        </Center>
      </ContentWrapper>
    </NoGCSessionWrapper>
  );
};

export default ActivityPage;
