import Header from "components/Header/Header";
import Center from "components/utility/Center/Center";
import ContentWrapper from "components/utility/ContentWrapper";
import EmptySpace from "components/utility/EmptySpace";
import PageWrapper from "components/utility/PageWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NGFooter from "components/Footers/NGFooter";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useAuthContext } from "hooks/useAuthContext";
import TotalScore from "../RatingAverage/TotalScore";
import { useNestedCollection } from "hooks/useNestedCollection";
import ParticipantSummary from "components/ParticipantSummary/ParticipantSummary";
import { Link } from "react-router-dom";

import { FaBackspace } from "react-icons/fa";
import { Participant } from "interface/ParticipantInterface";
import { getOrgsWithPermission } from "models/organisation";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import CheckOutFacilScreen from "components/CheckOutFacilScreen/CheckOutFacilScreen";
import Organisation from "interface/OrganisationInterface";

interface participantCollection {
  documents: Participant[];
}

const ObserverPage = () => {
  const { id } = useParams();

  const { documents: ParticipantRecords }: participantCollection =
    useNestedCollection(["GrowthCircles", "Participants"], [id], null, null);

  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const { growthCircleSession } = useGrowthCircleContext();
  const { profile } = useAuthContext();
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  useEffect(() => {
    if (!profile) return;
    getOrgsWithPermission(profile, P_SEE_AVERAGE_RATING).then(async (orgs) => {
      setOrganisations(orgs);
    });
  }, [profile]);

  // Function to count participants with checkOut records
  const countParticipantsCheckedOut = (
    participantRecords: Participant[]
  ): number => {
    return participantRecords.filter(
      (record) => record.checkOut !== null && record.checkOut !== undefined
    ).length;
  };

  useEffect(() => {
    if (ParticipantRecords.length === 0) return;
    const numberOfCheckedOutParticipants =
      countParticipantsCheckedOut(ParticipantRecords);
    setCountAllSubmitted(numberOfCheckedOutParticipants);
  }, [ParticipantRecords]);

  return (
    <>
      <PageWrapper>
        <Header hasModal={isParticipantModal || isRatingModal} />

        <ContentWrapper hasFiveStepper={true}>
          <div className="w-full relative">
            <Link
              className=" px-8 py-2 absolute -top-2 left-2"
              to={`/all-circles/join-session`}
              style={{ color: `var(--main-colour)` }}
            >
              <FaBackspace size={35} />
            </Link>
            <h2 className="text-center text-2xl md:text-3xl font-semibold">
              Session Summary
            </h2>
          </div>
          <Center>
            <EmptySpace height="5px" />
            <ParticipantSummary participants={ParticipantRecords} />
            <EmptySpace height="5px" />
            {ParticipantRecords.length > 0 && (
              <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
                <p className="text-sm font-semibold text-center">
                  Check out how everyone’s feeling!
                </p>
                <p className="text-center py-2 font-semibold   text-slate-600">
                  {countAllSubmitted} out of {ParticipantRecords.length}{" "}
                  submitted their checkouts
                </p>
              </div>
            )}

            <div className="w-full px-4 flex flex-col justify-center items-center">
              <CheckOutFacilScreen
                participants={ParticipantRecords}
                hasPermission={true}
                setModalState={setIsRatingModal}
              />
              {organisations.length > 0 ? (
                <TotalScore
                  participants={ParticipantRecords}
                  setIsModal={setIsParticipantModal}
                />
              ) : (
                ""
              )}
            </div>
            <EmptySpace height={"40px"} />

            <NGFooter type={growthCircleSession?.type} />
          </Center>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default ObserverPage;
