import EmptySpace from "components/utility/EmptySpace";
import Instruction from "components/utility/Instruction";
import useGetParticipants from "hooks/useGetParticipants";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { FC, Suspense, lazy, useEffect, useState } from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import BackAndNext from "components/utility/BackAndNext";
import { useIceBreaker } from "hooks/useIceBreaker";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

import { IceBreakers } from "interface/IceBreakerInterface";
import SimpleLoader from "components/Loaders/SimpleLoader";
import usePromise from "hooks/utility/usePromise";

const DiceSelection = lazy(() => import("components/Dice/DiceSelection"));

const DiceSelectionCustom = lazy(() => import("./DiceSelectionCustom"));
const ParticipantDiceSelectionImage = lazy(
  () => import("components/Dice/ParticipantDiceSelectionImage")
);
const ParticipantDiceSelection = lazy(
  () => import("components/Dice/ParticipantDiceSelection")
);

const INSTRUCTIONS_DICE =
  "Pick a picture that connects with you, and share your choice with your group";

const DiceComponent: FC<ComponentProps> = ({ backAndNextHandler }) => {
  const { participants } = useGetParticipants();
  const { growthCircleSession } = useGrowthCircleContext();
  const { iceBreakers } = useIceBreaker();
  const { selectedOrganisation } = useOrganisationContext();
  const [iceBreaker, setIceBreaker] = useState<IceBreakers | null>(null);

  const { isLoading, resolve } = usePromise();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchIce = async () => {
      if (iceBreakers && iceBreakers.length > 0) {
        const data = iceBreakers.filter(
          (icebreaker) =>
            icebreaker.type === selectedOrganisation?.iceBreakerType
        );
        const [_data] = data;

        if (_data !== undefined) {
          setIceBreaker(_data);
        } else {
          setIceBreaker(null);
        }

        const countdown = setTimeout(() => {
          setIsReady(true);
        }, 1000);
        return () => clearTimeout(countdown);
      } else {
        const countdown = setTimeout(() => {
          setIsReady(true);
        }, 1000);
        return () => clearTimeout(countdown);
      }
    };

    resolve(() => fetchIce());

    // eslint-disable-next-line
  }, [iceBreakers]);

  useEffect(() => {}, [
    growthCircleSession,
    selectedOrganisation,
    participants,
  ]);

  const ShowDiceDisplay = () => {
    if (growthCircleSession) {
      if (iceBreaker) {
        return (
          <>
            <Suspense fallback={"Loading..."}>
              <DiceSelectionCustom
                growthCircleSession={growthCircleSession}
                iceBreaker={iceBreaker}
              />
            </Suspense>
            <EmptySpace height={"40px"} />
            <Suspense fallback={"Loading..."}>
              <ParticipantDiceSelectionImage
                participants={participants}
                dices={growthCircleSession!.dicesImages}
                iceBreaker={iceBreaker}
              />
            </Suspense>
          </>
        );
      } else {
        return (
          <>
            {!isLoading && isReady ? (
              <>
                <Suspense fallback={"Loading..."}>
                  <DiceSelection growthCircleSession={growthCircleSession} />
                </Suspense>
                <EmptySpace height={"40px"} />{" "}
                <Suspense fallback={"Loading..."}>
                  <ParticipantDiceSelection
                    participants={participants}
                    dices={growthCircleSession!.dices}
                  />
                </Suspense>
              </>
            ) : (
              <div className="flex justify-center items-center min-h-[20vh]">
                <SimpleLoader />
              </div>
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <Instruction className="text-lg text-center" text={INSTRUCTIONS_DICE} />
      {selectedOrganisation && growthCircleSession && !isLoading && (
        <>{ShowDiceDisplay()}</>
      )}
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
    </>
  );
};

export default DiceComponent;
