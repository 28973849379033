import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import TotalScore from "../RatingAverage/TotalScore";
import { checkHasPermission } from "models/organisationRole";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import CheckOutFacilScreen from "components/CheckOutFacilScreen/CheckOutFacilScreen";
import { Participant } from "interface/ParticipantInterface";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import React from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const CheckInCheckOutAverageComponent = ({
  backAndNextHandler,
  setHasModal,
}: ComponentProps) => {
  const { ParticipantRecords, ParticipantRecord, sessionRole } =
    useParticipants();
  const [countAllSubmitted, setCountAllSubmitted] = useState(0);
  const [isParticipantsModal, setIsParticipantsModal] = useState(false);
  const [isParticipantModal, setIsParticipantModal] = useState(false);
  const [self, setSelf] = useState<Participant | undefined>(undefined);
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (isParticipantModal || isParticipantsModal) {
      setHasModal(isParticipantModal || isParticipantsModal);
      document.body.style.overflowY = "hidden";
    } else {
      setHasModal(false);
      document.body.style.overflowY = "unset";
    }
  }, [isParticipantModal, isParticipantsModal, setHasModal]);

  // Function to count participants with checkOut records
  const countParticipantsCheckedOut = (
    participantRecords: Participant[]
  ): number => {
    return participantRecords.filter(
      (record) =>
        record.checkOut.individually >= 0 && record.checkOut !== undefined
    ).length;
  };

  useEffect(() => {
    if (ParticipantRecord == null) return;
    setSelf(ParticipantRecord[0]);
  }, [ParticipantRecord]);

  useEffect(() => {
    if (ParticipantRecords.length === 0) return;
    const numberOfCheckedOutParticipants =
      countParticipantsCheckedOut(ParticipantRecords);
    setCountAllSubmitted(numberOfCheckedOutParticipants);
  }, [ParticipantRecords]);

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  return (
    <React.Fragment>
      <EmptySpace height="5px" />
      {ParticipantRecords.length > 0 && (
        <div className="py-4 border-b border-main-colour w-2/3 mx-auto">
          <p className="text-sm font-semibold text-center">
            {selectedOrganisation?.name === "NTU Career & Attachment Office"
              ? "Check out how everyone’s progress!"
              : "Check out how everyone’s feeling!"}
          </p>
          <p className="text-center py-2 font-semibold   text-slate-600">
            {countAllSubmitted} out of {ParticipantRecords.length} submitted
          </p>
        </div>
      )}

      <CheckOutFacilScreen
        organisation={selectedOrganisation || undefined}
        participants={ParticipantRecords}
        self={self}
        hasPermission={checkHasPermission(sessionRole, P_SEE_AVERAGE_RATING)}
        setModalState={setIsParticipantsModal}
      />

      {checkHasPermission(sessionRole, P_SEE_AVERAGE_RATING) ? (
        <TotalScore
          organisation={selectedOrganisation || undefined}
          participants={ParticipantRecords}
          setIsModal={setIsParticipantModal}
        />
      ) : (
        ""
      )}

      <EmptySpace height={"40px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
};

export default CheckInCheckOutAverageComponent;
