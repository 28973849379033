import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Logo from "../utility/Logo";
import { ReactComponent as HamburgerMenu } from "../../assets/HamburgerMenu.svg";
import SidebarModal from "../Sidebar/SidebarModal";

import classes from "./Header.module.css";
import useGetParticipants from "hooks/useGetParticipants";
import { useAuthContext } from "hooks/useAuthContext";
import { useLogout } from "hooks/useLogout";
import { FaSignOutAlt } from "react-icons/fa";

interface HeaderProps {
  hasModal: boolean;
  hasSidebar?: boolean;
  title?: string;
}

const Header = ({ hasModal, hasSidebar = true, title }: HeaderProps) => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [haveModal, setHasModal] = useState(false);
  const { triggeredParticipants } = useGetParticipants();
  const { profile } = useAuthContext();
  const { logout } = useLogout();
  const hamburgerMenuHandler = () => {
    setSidebarActive((prev) => !prev);
  };

  useEffect(() => {
    if (!profile?.isFacil) return;
    if (triggeredParticipants.length === 0) {
      setHasModal(false);
    } else {
      setHasModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.isFacil, triggeredParticipants]);

  useEffect(() => {
    if (sidebarActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  });

  const callBack = () => {
    if (!haveModal) {
      setHasModal(true);
    } else {
      setHasModal(false);
    }
  };

  return (
    <div
      id="header"
      className={`${classes.header}`}
      style={{
        zIndex: hasModal || hasModal || haveModal ? 1 : 100000000000,
      }}
    >
      {hasSidebar && (
        <>
          <SidebarModal
            sidebar={sidebarActive}
            callBack={callBack}
            backdropClick={hamburgerMenuHandler}
          />
          <div className={classes.icon}>
            <HamburgerMenu
              onClick={hamburgerMenuHandler}
              viewBox="0 0 25 22"
              stroke={"var(--main-colour)"}
            />
          </div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Logo text={title} />
          </Link>
          <span />
        </>
      )}
      {!hasSidebar && (
        <div className="w-full text-center flex justify-center items-center relative">
          <Link to="/" style={{ textDecoration: "none" }}>
            <Logo />
          </Link>
          <span
            onClick={logout}
            style={{ color: "var(--main-colour)" }}
            className="absolute right-0 md:right-[5%] cursor-pointer  py-2 pl-3 pr-4 flex gap-2 rounded  md:p-0"
          >
            <FaSignOutAlt size={20} />{" "}
            <span className="hidden md:block">Logout</span>
          </span>
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  hasModal: false,
};

export default Header;
