type permissionDescriptionsProps = {
  [key: string]: { category: string; description: string };
};

export const P_GENERATE_QR_CODE = "Generate QR code";
export const P_REMOVE_PROFILE_PICTURE = "Remove profile picture";
export const P_VIEW_JOURNALS = "View all journals";
export const P_GRADE_JOURNALS = "Grade all journals";

export const P_ADVANCE_NEXT_ACTIVITY = "Advance next activity";
export const P_ASSIGN_CO_HOST = "Assign co-host";
export const P_BECOME_CO_HOST = "Become co-host";

export const P_SEE_AVERAGE_RATING = "See average rating";
export const P_USE_TIMER = "Use timer";
export const P_SKIP_SAVE_JOURNAL = "Skip save journal";

export const P_CAN_OBSERVE = "Join as observer";
export const P_CAN_ASSIGN_GROUP_LEADER =
  "Can assign group and sub-group leaders";
export const P_CAN_VIEW_FEEDBACKS = "Can view journal feedback";

export const P_CAN_VIEW_DASHBOARD = "Can view organisation dashboard";
export const P_CAN_EDIT_DASHBOARD = "Can edit organisation dashboard";

export const P_CAN_ACCESS_SELFAWARENESS = "Can use/access self discovery tools";
export const P_CAN_VIEW_SELFAWARENESS_JOURNAL =
  "Can view personal self awareness journals";
export const P_CAN_VIEW_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL =
  "Can view assigned group self discovery journals";
export const P_CAN_GRADE_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL =
  "Can grade assigned group's self discovery journals";

export const P_CAN_VIEW_OVERALL_SELF_DISCOVERY =
  "Can view self discovery overall data";

export const permissionDescriptions: permissionDescriptionsProps = {
  [P_GENERATE_QR_CODE]: {
    category: "session",
    description: "Ability to generate a growthcircle session QR code",
  },
  [P_REMOVE_PROFILE_PICTURE]: {
    category: "admin",
    description: "Ability to remove a user's profile picture",
  },
  [P_VIEW_JOURNALS]: {
    category: "journal",
    description: "Ability to view all journals in an organisation",
  },
  [P_GRADE_JOURNALS]: {
    category: "journal",
    description: "Ability to grade/feedback all journals in an organisation",
  },

  [P_SKIP_SAVE_JOURNAL]: {
    category: "journal",
    description: "Ability to skip past saving journal",
  },
  [P_CAN_VIEW_FEEDBACKS]: {
    category: "journal",
    description:
      "Ability to view own journal feedbacks from a growthcircle session.",
  },

  [P_ADVANCE_NEXT_ACTIVITY]: {
    category: "session",
    description:
      "Ability to unlock and advance the session next activity, allowing all participants to proceed.",
  },
  [P_ASSIGN_CO_HOST]: {
    category: "session",
    description: "Ability to assign users as co-host",
  },
  [P_BECOME_CO_HOST]: {
    category: "session",
    description: "Ability to be assigned as co-host",
  },
  [P_SEE_AVERAGE_RATING]: {
    category: "admin",
    description:
      "Ability to see the average feeling ratings of all participants",
  },
  [P_CAN_ASSIGN_GROUP_LEADER]: {
    category: "admin",
    description: "Ability to assign group and sub-group leaders.",
  },

  [P_USE_TIMER]: {
    category: "session",
    description: "Ability to use timer to manage sharing durations",
  },
  [P_CAN_OBSERVE]: {
    category: "admin",
    description: "Ability to join as a observer in a session",
  },

  [P_CAN_VIEW_DASHBOARD]: {
    category: "admin",
    description:
      "Ability to view the organisation dashboard and see statistical data's",
  },
  [P_CAN_EDIT_DASHBOARD]: {
    category: "admin",
    description:
      "Ability to select which statistical data is displayed on the organisation dashboard",
  },
  [P_CAN_ACCESS_SELFAWARENESS]: {
    category: "self-discovery",
    description: "Ability to view the self-awareness journal entries.",
  },
  [P_CAN_VIEW_SELFAWARENESS_JOURNAL]: {
    category: "self-discovery",
    description: "Ability to view personal self discovery journals",
  },
  [P_CAN_VIEW_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: {
    category: "self-discovery",
    description: "Ability to view assigned group's self discovery journals",
  },
  [P_CAN_GRADE_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: {
    category: "self-discovery",
    description: "Ability to grade assigned group's self discovery journals",
  },
  [P_CAN_VIEW_OVERALL_SELF_DISCOVERY]: {
    category: "self-discovery",
    description: "Ability to view self discovery data inside an organisation.",
  },
};

/**
 * Role permissions to describe whether a user of a certain role can
 * proceed to next page, see average ratings etc.
 */
export type PermissionName = keyof typeof permissionDescriptions;

export type Permissions = { [p in PermissionName]: boolean | string | number };

export const defaultPermissions: Permissions = {
  [P_GENERATE_QR_CODE]: false,
  [P_REMOVE_PROFILE_PICTURE]: false,
  [P_VIEW_JOURNALS]: false,
  [P_GRADE_JOURNALS]: false,
  [P_ADVANCE_NEXT_ACTIVITY]: false,
  [P_ASSIGN_CO_HOST]: false,
  [P_BECOME_CO_HOST]: false,
  [P_SEE_AVERAGE_RATING]: false,
  [P_USE_TIMER]: false,
  [P_CAN_OBSERVE]: false,
  [P_SKIP_SAVE_JOURNAL]: false,
  [P_CAN_VIEW_FEEDBACKS]: false,
  [P_CAN_VIEW_DASHBOARD]: false,
  [P_CAN_EDIT_DASHBOARD]: false,
  [P_CAN_ASSIGN_GROUP_LEADER]: false,
  [P_CAN_ACCESS_SELFAWARENESS]: false,
  [P_CAN_VIEW_SELFAWARENESS_JOURNAL]: false,
  [P_CAN_VIEW_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: false,
  [P_CAN_GRADE_ASSIGNED_GROUP_SELFAWARENESS_JOURNAL]: false,
  [P_CAN_VIEW_OVERALL_SELF_DISCOVERY]: false,
};
