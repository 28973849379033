import Slider from "components/Slider/Slider";
import { useAuthContext } from "hooks/useAuthContext";
import { DRAG_RATING_KEYS, DragRatingKeys } from "hooks/useDragRating";
import { useGCSession } from "hooks/useGCSession";
import useGetParticipants from "hooks/useGetParticipants";
import { useEffect, useState } from "react";
import classes from "./RatingAverage.module.css";
import UserBarHandle from "components/RatingAverage/UserBarHandle";
import { CheckInCheckOut } from "interface/ParticipantInterface";
import { useFirestore } from "hooks/useFirestore";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import TotalScore from "./TotalScore";
import { checkHasPermission } from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

export const DRAG_RATING_KEYS_ORG: DragRatingKeys = {
  INDIVIDUALLY: {
    KEY: "individually",
    TITLE: "Industry insights",
    SUB_TITLE: "",
  },
  INTERPERSONALLY: {
    KEY: "interpersonally",
    TITLE: "Job Roles",
    SUB_TITLE: "",
  },
  SOCIALLY: {
    KEY: "socially",
    TITLE: "Career Paths and Progression",
    SUB_TITLE: "",
  },
  OVERALL: {
    KEY: "overall",
    TITLE: "Skills Relevant To Industry",
    SUB_TITLE: "",
  },
};

/**
 * Page that shows the average of all participants after user has rated their initial checkIns
 */
const RatingAverageComponent = ({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) => {
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const PAGE_CONSTANTS = {
    PAGE_TITLE:
      selectedOrganisation?.name === "NTU Career & Attachment Office"
        ? ""
        : "Check out how everyone's feeling!",
  };

  const INSTRUCTIONS_RATING_AVERAGE =
    selectedOrganisation?.name === "NTU Career & Attachment Office"
      ? "Check out average of everyone's clarity about their journey"
      : "Embrace the different emotional states and wellbeing of everyone";

  const { updateDocument } = useFirestore("users");

  const { profile } = useAuthContext();
  const { participants, getGroupedParticipantsByCheckInKey } =
    useGetParticipants();
  const { calculateAverageCheckIn, checkAllRatingAveragesNotNan } =
    useGCSession();
  const { sessionRole } = useParticipants();
  const [averages, setAverages] = useState<CheckInCheckOut | null | undefined>(
    null
  );
  const [showContent, setShowContent] = useState(false);
  const [currentCheckInType, setCurrentCheckInType] = useState<string | null>(
    ""
  );

  const getOtherValues = (key) => {
    const groupedParticipants = getGroupedParticipantsByCheckInKey(
      key,
      participants
    );

    return groupedParticipants.map((participants, index) => {
      //Map into a component depending on the length
      return (
        <UserBarHandle
          max={selectedOrganisation?.maxRating ?? 10}
          key={index}
          checkInType={DRAG_RATING_KEYS[key].KEY + index}
          currentCheckInType={currentCheckInType}
          participants={participants}
          values={
            participants[0].checkIn[DRAG_RATING_KEYS[key].KEY] >= 0
              ? [participants[0].checkIn[DRAG_RATING_KEYS[key].KEY]]
              : []
          }
          setCurrentCheckInType={setCurrentCheckInType}
        />
      );
    });
  };

  const countAllSubmitted = () => {
    let count = 0;
    participants.map((p) => (p.checkIn.individually >= 0 ? count++ : 0));
    return count;
  };

  useEffect(() => {
    if (!profile) return;
    if (!!participants && participants.length > 0) {
      setAverages(calculateAverageCheckIn(participants));
    }
    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, profile]);

  useEffect(() => {
    //Check if all checkIn keys are true
    if (averages) {
      setShowContent(checkAllRatingAveragesNotNan(averages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averages]);

  return (
    <div className={classes["rating-average-container"]}>
      <div className={classes["title"]}>
        <h1 className="text-sm font-semibold text-center">
          {PAGE_CONSTANTS.PAGE_TITLE}
        </h1>
        <h2 className="text-center py-2 font-semibold text-slate-600">
          {countAllSubmitted()} out of {participants.length} submitted
        </h2>
        <hr />
      </div>
      <Instruction text={INSTRUCTIONS_RATING_AVERAGE} />
      {/* Content */}
      {Object.keys(DRAG_RATING_KEYS).map((key, index) => {
        return (
          showContent && (
            <div key={key} className={classes["rating-row"]}>
              {/* textual content */}
              <div className={classes["textual-content"]}>
                <h2>
                  {selectedOrganisation?.name ===
                  "NTU Career & Attachment Office"
                    ? DRAG_RATING_KEYS_ORG[key].TITLE
                    : DRAG_RATING_KEYS[key].TITLE}
                </h2>
                <h3>
                  {selectedOrganisation?.name ===
                  "NTU Career & Attachment Office"
                    ? DRAG_RATING_KEYS_ORG[key].SUB_TITLE
                    : DRAG_RATING_KEYS[key].SUB_TITLE}{" "}
                </h3>
              </div>
              {/* slider */}

              {selectedOrganisation?.setMaxRating ? (
                <Slider
                  max={selectedOrganisation?.maxRating ?? 10}
                  values={[averages![DRAG_RATING_KEYS[key].KEY].toFixed(1)]}
                  setValues={[0]}
                  icon={false}
                  isDisabled={true}
                  // eslint-disable-next-line react/style-prop-object
                  style={"average"}
                  otherValues={getOtherValues(key)}
                />
              ) : (
                <Slider
                  max={10}
                  values={[averages![DRAG_RATING_KEYS[key].KEY].toFixed(1)]}
                  setValues={[0]}
                  icon={false}
                  isDisabled={true}
                  // eslint-disable-next-line react/style-prop-object
                  style={"average"}
                  otherValues={getOtherValues(key)}
                />
              )}
            </div>
          )
        );
      })}
      <EmptySpace height="2rem" />
      {checkHasPermission(sessionRole, P_SEE_AVERAGE_RATING) ? (
        <TotalScore
          organisation={selectedOrganisation || undefined}
          participants={participants}
          setIsModal={setHasModal}
        />
      ) : (
        ""
      )}
      <BackAndNext {...backAndNextHandler} />
    </div>
  );
};

export default RatingAverageComponent;
