import Header from "components/Header/Header";
import PageWrapper from "components/utility/PageWrapper";
import { useAuthContext } from "hooks/useAuthContext";
import toast, { Toaster } from "react-hot-toast";
import classes from "../ProfileSettings/ProfileSettings.module.css";
import InputField from "components/utility/Forms/InputField";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import EmptySpace from "components/utility/EmptySpace";
import { checkPasswordError } from "utility/inputValidation";
import useUser from "hooks/useUser";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import usePromise from "hooks/utility/usePromise";
import { useNavigate } from "react-router-dom";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import Organisation, { OrganisationDropdownItem } from "interface/OrganisationInterface";
import { getOrgById } from "models/organisation";
import PasswordChecker from "../Login/PasswordChecker";

const ChangePassword = () => {
  const { profile } = useAuthContext();
  const [formCurrentPassword, setFormCurrentPassword] = useState("");
  const [formNewPassword, setFormNewPassword] = useState("");
  const [formConfirmPassword, setFormConfirmPassword] = useState("");
  const { setPassword, formatAuthError, updateProfileInfo } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoading, resolve } = usePromise();
  const navigate = useNavigate();
  const { selectedOrganisation } = useOrganisationContext();

  const [organisationObject, setOrganisationObject] = useState<Organisation>();

  const [selectedValues, setSelectedValues] = useState<{
    [label: string]: string;
  }>({});

  const [textareaValues, setTextareaValues] = useState<{
    [label: string]: string;
  }>({});

  const handleDropdownChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");

    setSelectedValues({
      ...selectedValues,
      [formattedLabel]: value,
    });

    // If "Others" is selected in the dropdown, clear the textarea value
    if (value === "Others") {
      setTextareaValues({
        ...textareaValues,
        [formattedLabel]: "",
      });
    }
  };

  const handleTextareaChange = (label: string, value: string) => {
    const formattedLabel = label.replace(/ /g, "_");

    setTextareaValues({
      ...textareaValues,
      [formattedLabel]: value,
    });
  };

  useEffect(() => {
    if (!profile) return;
    if (!selectedOrganisation) return;
  }, [profile, selectedOrganisation]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    getOrgById(selectedOrganisation.id).then((doc) => {
      if (doc) {
        if (!organisationObject) setOrganisationObject(doc);
      }
    });

    // eslint-disable-next-line
  }, [selectedOrganisation, organisationObject]);

  const OrgID = organisationObject?.id;

  // Initialize an empty object to store the collected data with a dynamic key
  const collectedDataObject: {
    [key: string]: { label: string; value: string; otherValues?: string }[];
  } = {
    [OrgID || "default"]: [], // Use OrgID as the key, or a default key if OrgID is falsy
  };

  // Collect the data and populate the object
  Object.keys(selectedValues).forEach((label) => {
    const originalLabel = label.replace(/_/g, " "); // Revert back to original label format

    collectedDataObject[OrgID || "default"].push({
      label: originalLabel,
      value: selectedValues[label],
      otherValues: textareaValues[label] ?? "",
    });
  });

  const confirmHandler = async () => {
    resolve(() =>
      setPassword(formCurrentPassword, formNewPassword)
        .then(async () => {
          if (profile)
            await updateProfileInfo(profile?.uid, collectedDataObject);
          toast.success("Password successfully changed");
          setIsModalOpen(!isModalOpen);
          setFormNewPassword("");
          setFormConfirmPassword("");
          setFormCurrentPassword("");
          navigate("/");
        })
        .catch((e) => {
          toast.error(formatAuthError(e));
        })
    );
  };

  /**
   * Checks that both password fields match and displays feedback to the user if they are not.
   *
   * @param password Password user input.
   * @param confirmPassword Confirm password user input.
   */
  const passwordInputsHandler = (password: string, confirmPassword: string) => {
    setFormNewPassword(password);
    setFormConfirmPassword(confirmPassword);
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formNewPassword === formConfirmPassword) {
    } else {
      toast.error("password does not match");
    }
  };
  return (
    <PageWrapper pageType={"groundRules"}>
      <Modal
        show={isModalOpen}
        size="md"
        popup={true}
        className="min-h-screen"
        onClose={() => setIsModalOpen(!isModalOpen)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-2 h-24 w-24 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to change your password? You can still
              update this on your "My Profile" page.
            </h3>

            <div className="flex justify-center gap-4">
              <Button
                disabled={isLoading}
                className="shadow-md"
                color="failure"
                onClick={confirmHandler}
              >
                <span>
                  {isLoading === false ? "Yes, I'm sure" : "Processing..."}
                </span>
              </Button>
              <Button
                disabled={isLoading}
                className="shadow-md"
                color="gray"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Header hasSidebar={false} hasModal={isModalOpen} />
      <Toaster
        position="top-center"
        containerStyle={{
          top: "15%",
        }}
        toastOptions={{ duration: 4000 }}
      />
      <div className="mb-[200px]" style={{ marginTop: `calc(12vh)` }}>
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.inputs}>
            {organisationObject?.hasUniqueFields && (
              <>
                {organisationObject.uniqueFields &&
                  organisationObject.uniqueFields.map(
                    (item: OrganisationDropdownItem, index) =>
                      item.field_type === "dropdown" ? (
                        <div key={index}>
                          <div className="py-2">
                            <label className="text-slate-600 py-2">
                              {item.label}
                            </label>
                            <InputField
                              type="select"
                              isRequired={true}
                              onChange={(e) =>
                                handleDropdownChange(item.label, e.target.value)
                              }
                              options={[...item.options]}
                            />
                          </div>

                          {selectedValues[item.label.replace(/ /g, "_")] ===
                            "Others" && (
                            <div>
                              <textarea
                                className="w-full"
                                required
                                value={
                                  textareaValues[
                                    item.label.replace(/ /g, "_")
                                  ] || ""
                                }
                                onChange={(e) =>
                                  handleTextareaChange(
                                    item.label,
                                    e.target.value
                                  )
                                }
                                placeholder={`Others ${item.label}`}
                              />
                            </div>
                          )}
                        </div>
                      ) : null
                  )}
              </>
            )}

            <InputField
              text="Current Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormCurrentPassword(e.target.value)
              }
              value={formCurrentPassword}
              htmlFor={"currentpassword"}
              label={"Current Password"}
            />
            <InputField
              text="New Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                passwordInputsHandler(e.target.value, formConfirmPassword)
              }
              value={formNewPassword}
              htmlFor={"password"}
              label={"New Password"}
            />
            <InputField
              text="Confirm Password"
              isRequired={true}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                passwordInputsHandler(formNewPassword, e.target.value)
              }
              value={formConfirmPassword}
              htmlFor={"confirm-password"}
              label={"Re-enter New Password"}
            />
          </div>
          <EmptySpace />
          <PasswordChecker 
          password={formNewPassword}
          confirm_password={formConfirmPassword}
          />
          <EmptySpace />
          <div className="float-right">
            <Button
              disabled={
                !formCurrentPassword || !formNewPassword || !formConfirmPassword || !checkPasswordError(formNewPassword) || !checkPasswordError(formConfirmPassword) || !(formNewPassword === formConfirmPassword)
              }
              style={{ backgroundColor: "var(--main-colour)" }}
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Update Information
            </Button>
          </div>
        </form>
      </div>
    </PageWrapper>
  );
};

export default ChangePassword;
