import SessionCards from "components/Admin/GrowthCircles/SessionCards";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { useGrowthCircles } from "hooks/useGrowthCircles";

import usePromise from "hooks/utility/usePromise";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import Organisation from "interface/OrganisationInterface";
import { useEffect, useState } from "react";
import { inputClass } from "strings/InputClassStrings";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import EmptySpace from "components/utility/EmptySpace";
import { BiXCircle } from "react-icons/bi";

type JoinGrowthCirclesListsProps = {
  organisation: Organisation;
  setHasModal: (value: React.SetStateAction<boolean>) => void;
};

const JoinGrowthCirclesLists = ({
  organisation,
  setHasModal,
}: JoinGrowthCirclesListsProps) => {
  const [GrowthCircles, setGrowthCircles] = useState<GrowthCircleSession[]>([]);
  const [localGCs, setLocalGCs] = useState<GrowthCircleSession[]>([]);
  const [filteredGCs, setFilteredGCs] = useState<GrowthCircleSession[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { getAllGc } = useGrowthCircles();
  const { isLoading, resolve } = usePromise();
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredGCs.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const paginationItems: JSX.Element[] = [];

    const createPaginationItem = (i: number) => (
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{
          backgroundColor:
            currentPage === i ? "var(--icon-colour-2)" : "var(--main-colour)",
        }}
        className={`my-5 mx-1 px-3 py-1 rounded-md `}
      >
        {i}
      </button>
    );

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    } else {
      paginationItems.push(createPaginationItem(1));

      if (currentPage > 3) {
        paginationItems.push(
          <span key="ellipsis1" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(createPaginationItem(i));
      }

      if (currentPage < totalPages - 2) {
        paginationItems.push(
          <span key="ellipsis2" className="my-5 mx-1 px-3 py-1">
            ...
          </span>
        );
      }

      paginationItems.push(createPaginationItem(totalPages));
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {paginationItems}
        <button
          onClick={() => paginate(currentPage + 1)}
          className="my-5 mx-1 px-3 py-1 rounded-md bg-gray-200"
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    );
  };

  // Handle search input change
  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const fetchAllGC = async () => {
    try {
      if (organisation) {
        const _growthCircles = (await getAllGc(
          organisation.name
        )) as GrowthCircleSession[];

        setGrowthCircles(_growthCircles);
        setLocalGCs(_growthCircles);
      } else {
        setGrowthCircles([]);
        setLocalGCs([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    resolve(() => fetchAllGC());
    // eslint-disable-next-line
  }, [organisation]);

  useEffect(() => {
    const filtered = localGCs.filter((gc) => {
      const matchesSearchQuery = gc.name.toLowerCase().includes(searchQuery);
      return matchesSearchQuery;
    });

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes

    // eslint-disable-next-line
  }, [localGCs, organisation]);

  const searchSubmitHandler = () => {
    const filtered = localGCs.filter((gc) => {
      const matchesSearchQuery = gc.name.toLowerCase().includes(searchQuery);
      return matchesSearchQuery;
    });

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const clearHandler = () => {
    setSearchQuery("");
    const filtered = localGCs.filter((gc) => {
      const matchesSearchQuery = gc.name.toLowerCase().includes("");
      return matchesSearchQuery;
    });

    setFilteredGCs(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredGCs.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {GrowthCircles.length > 0 && (
        <div
          className={`${classes["container"]} relative flex items-center justify-end w-1/3 mt-2 gap-2`}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={searchChangeHandler}
            className={inputClass}
            style={{ minWidth: "200px" }}
          />
          <button
            className={`${classes["button"]}`}
            style={{ backgroundColor: "var(--main-colour)" }}
            onClick={searchSubmitHandler}
          >
            Search
          </button>
          {searchQuery.length > 0 && (
            <BiXCircle
              onClick={clearHandler}
              className="cursor-pointer"
              size={62}
              color={"var(--main-colour)"}
            />
          )}
        </div>
      )}

      <div className="grid grid-1 md:grid-cols-4 gap-4 mt-2">
        {currentItems.map((gc: GrowthCircleSession, index: number) => (
          <SessionCards
            key={index} // some old GC have no uid
            gc={gc}
            join={setHasModal}
            organisation={organisation}
          />
        ))}

        {/* Load more button */}
      </div>
      {filteredGCs.length > 0 ? (
        <>
          {" "}
          <div>{renderPaginationButtons()}</div>
        </>
      ) : (
        <>
          {!isLoading ? (
            <div className="min-h-[10vh] flex justify-center items-center">
              <p>No active sessions</p>
            </div>
          ) : (
            <div className="w-full mt-2 min-[30vh] flex justify-center items-center">
              {" "}
              <SimpleLoader />
            </div>
          )}
        </>
      )}
      <EmptySpace />
    </>
  );
};

export default JoinGrowthCirclesLists;
