import { projectFirestore } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { useEffect, useState } from "react";

import { Participant } from "interface/ParticipantInterface";
import { DocumentData } from "firebase/firestore";
import { useAuthContext } from "./useAuthContext";

export const useParticipantHistoryJournals = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const { profile } = useAuthContext();
  const [journals, setJournals] = useState<Participant[]>([]);
  const [selectedJournal, setSelectedJournal] = useState<Participant | null>(
    null
  );
  const [myJournals, setMyJournals] = useState<Participant[]>([]);
  const [groupJournals, setGroupJournals] = useState<Participant[]>([]);
  const [lastDoc, setLastDoc] = useState<DocumentData | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true); // New state variable
  const [myLastDoc, setMyLastDoc] = useState<DocumentData | null>(null);
  const [lastGroupDoc, setLastGroupDoc] = useState<DocumentData | null>(null);
  const [more, setMore] = useState<boolean>(true); // New state
  const [loading, setLoading] = useState(true);
  const [groupMore, setGroupMore] = useState<boolean>(true); // New state
  const [subGroupMore, setSubGroupMore] = useState<boolean>(true);
  const [lastSubGroupDoc, setLastSubGroupDoc] = useState<DocumentData | null>(
    null
  );
  const [subGroupJournals, setSubGroupJournals] = useState<Participant[]>([]);
  const [userLastDoc, setUserLastDoc] = useState<DocumentData | null>(null);
  const [userJournals, setUserJournals] = useState<Participant[]>([]);
  const [userMore, setUserMore] = useState<boolean>(true); // New state

  const fetchParticipantHistoryJournal = async () => {
    try {
      let query = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("participantHistory")
        .where("feedbackReflectionStatus", "==", "submitted")
        .orderBy("createdAt", "desc")
        .limit(10);

      if (lastDoc) {
        query = query.startAfter(lastDoc);
      }

      query = query.limit(10);

      const snapshot = await query.get();

      const updatedParticipant: Participant[] = [];
      snapshot.forEach((doc) => {
        updatedParticipant.push(doc.data() as Participant);
      });

      setJournals((prevParticipantHistory) => [
        ...prevParticipantHistory,
        ...updatedParticipant,
      ]);

      if (snapshot.docs.length <= 0) {
        setHasMore(false);
      } else {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching participant history:", error);
    }
  };

  const initialGroupHistoryData = async (ids: string[]) => {
    try {
      if (ids && ids.length > 0) {
        const batchSize = 10;
        const batches: string[][] = [];
        for (let i = 0; i < ids.length; i += batchSize) {
          batches.push(ids.slice(i, i + batchSize));
        }

        let hasMore = false; // Initialize hasMore flag

        // Process each batch of user IDs separately
        for (const batch of batches) {
          let query = projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("participantHistory")
            .where("userId", "in", batch) // Use 'in' for querying multiple user IDs in each batch
            .where("feedbackReflectionStatus", "==", "submitted")
            .orderBy("createdAt", "desc")
            .limit(10); // Limit documents per batch

          const snapshot = await query.get();

          const updatedParticipant: Participant[] = [];
          snapshot.forEach((doc) => {
            updatedParticipant.push(doc.data() as Participant);
          });

          setGroupJournals(updatedParticipant);

          if (snapshot.docs.length <= 0) {
            hasMore = false;
          } else {
            setLastGroupDoc(snapshot.docs[snapshot.docs.length - 1]);
            hasMore = true;
          }
        }
        setGroupMore(hasMore);
      } else {
        // Clear state if IDs array is empty
        setGroupJournals([]);
        setLastGroupDoc(null);
        setGroupMore(false);
      }
    } catch (error) {
      console.error("Error fetching participant history:", error);
    }
  };

  const fetchParticipantHistoryJournalByIds = async (ids: string[]) => {
    try {
      if (ids.length > 0) {
        const batchSize = 10;
        const batches: string[][] = [];
        for (let i = 0; i < ids.length; i += batchSize) {
          batches.push(ids.slice(i, i + batchSize));
        }

        let hasMore = true; // Initialize hasMore flag

        // Process each batch of user IDs separately
        for (const batch of batches) {
          let query = projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("participantHistory")
            .where("userId", "in", batch) // Use 'in' for querying multiple user IDs in each batch
            .where("feedbackReflectionStatus", "==", "submitted")
            .orderBy("createdAt", "desc")
            .limit(10); // Limit documents per batch

          if (lastGroupDoc) {
            query = query.startAfter(lastGroupDoc);
          }

          const snapshot = await query.get();

          const updatedParticipant: Participant[] = [];
          snapshot.forEach((doc) => {
            updatedParticipant.push(doc.data() as Participant);
          });

          setGroupJournals((prevParticipantHistory) => [
            ...prevParticipantHistory,
            ...updatedParticipant,
          ]);

          if (snapshot.docs.length <= 0) {
            hasMore = false;
          } else {
            setLastGroupDoc(snapshot.docs[snapshot.docs.length - 1]);
          }
        }
        setGroupMore(hasMore);
      } else {
        // Clear state if IDs array is empty
        setGroupJournals([]);
        setLastGroupDoc(null);
        setGroupMore(false);
      }
    } catch (error) {
      console.error("Error fetching participant history:", error);
    }
  };

  //TODO: this is a duplicate function but since it must be using the startAfter query to load more.
  const fetchJournalByIds = async (ids: string[]) => {
    try {
      const batchSize = 10;
      const batches: string[][] = [];
      for (let i = 0; i < ids.length; i += batchSize) {
        batches.push(ids.slice(i, i + batchSize));
      }

      let hasMore = true; // Initialize hasMore flag

      // Process each batch of user IDs separately
      for (const batch of batches) {
        let query = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("participantHistory")
          .where("userId", "in", batch) // Use 'in' for querying multiple user IDs in each batch
          .where("feedbackReflectionStatus", "==", "submitted")
          .orderBy("createdAt", "desc")
          .limit(10); // Limit documents per batch

        if (lastSubGroupDoc) {
          query = query.startAfter(lastSubGroupDoc);
        } else {
          setSubGroupJournals([]);
        }

        const snapshot = await query.get();

        const updatedParticipant: Participant[] = [];
        snapshot.forEach((doc) => {
          updatedParticipant.push(doc.data() as Participant);
        });

        setSubGroupJournals((prevParticipantHistory) => [
          ...prevParticipantHistory,
          ...updatedParticipant,
        ]);

        if (snapshot.docs.length <= 0) {
          hasMore = false;
        } else {
          setLastSubGroupDoc(snapshot.docs[snapshot.docs.length - 1]);
        }
      }

      setSubGroupMore(hasMore); // Update hasMore flag after processing all batches
    } catch (error) {
      console.error("Error fetching participant history:", error);
    }
  };

  const fetchMyHistoryJournal = async () => {
    try {
      let query = projectFirestore
        .collectionGroup("participantHistory")
        .where("userId", "==", profile?.uid)
        .where("feedbackReflectionStatus", "==", "submitted")
        .orderBy("createdAt", "desc")
        .limit(10);

      if (myLastDoc) {
        query = query.startAfter(myLastDoc);
      } else {
        setMyJournals([]);
      }

      query = query.limit(10);

      const snapshot = await query.get();

      const updatedParticipant: Participant[] = [];
      snapshot.forEach((doc) => {
        updatedParticipant.push(doc.data() as Participant);
      });

      setMyJournals((prevParticipantHistory) => [
        ...prevParticipantHistory,
        ...updatedParticipant,
      ]);

      if (snapshot.docs.length <= 0) {
        setMore(false);
      } else {
        setMyLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching participant history:", error);
    }
  };

  const selectJournalById = async (id: string, callback: () => void) => {
    try {
      const querySnapshot = await projectFirestore
        .collectionGroup("participantHistory")
        .where("id", "==", id)
        .where("feedbackReflectionStatus", "==", "submitted")
        .get();

      if (querySnapshot.empty) {
        console.debug("No journal found for ID:", id);
        return; // No document found, handle appropriately
      }

      const journal = querySnapshot.docs[0].data() as Participant;
      setSelectedJournal(journal);
      callback();
      // Handle the fetched data as needed
    } catch (error) {
      // Handle errors here
      console.error("Error fetching journal:", error);
    }
  };

  const unselectJournal = async () => {
    setMyJournals([]);
    setJournals([]);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const query = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("participantHistory")
          .where("feedbackReflectionStatus", "==", "submitted")
          .orderBy("createdAt", "desc")
          .limit(10);

        const snapshot = await query.get();

        const initialParticipantHistory: Participant[] = [];
        snapshot.forEach((doc) => {
          initialParticipantHistory.push(doc.data() as Participant);
        });

        setJournals(initialParticipantHistory);

        if (snapshot.docs.length <= 0) {
          setHasMore(false); // No more documents to fetch initially
        } else {
          setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        }
      } catch (error) {
        console.error("Error fetching initial participant history:", error);
      }
    };

    if (selectedOrganisation) {
      loadInitialData();
    }

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const updateParticipantFeedback = async (id: string, feedback: string) => {
    try {
      const querySnapshot = await projectFirestore
        .collectionGroup("participantHistory")
        .where("feedbackReflectionStatus", "==", "submitted")
        .where("id", "==", id)
        .get();

      querySnapshot.forEach(async (doc) => {
        // Update the document with your desired changes
        await doc.ref.update({ feedback: feedback });
      });

      console.debug("Participant feedback updated successfully.");
    } catch (error) {
      console.error("Error updating participant feedback:", error);
    }
  };

  const getAllJournalByUser = async (id: string) => {
    // Clear previous user journals
    setUserJournals([]);

    let query = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("participantHistory")
      .where("userId", "==", id)
      .where("feedbackReflectionStatus", "==", "submitted")
      .orderBy("createdAt", "desc");

    if (userLastDoc) {
      query = query.startAfter(userLastDoc);
    }

    let hasMore = true;

    try {
      const snapshot = await query.get();

      if (snapshot.empty) {
        console.debug("No matching documents.");
        setUserMore(false);
        return;
      }

      const updatedParticipant: Participant[] = [];
      snapshot.forEach((doc) => {
        updatedParticipant.push(doc.data() as Participant);
      });
      setUserJournals((prevJournals) => [
        ...prevJournals,
        ...updatedParticipant,
      ]);

      if (snapshot.docs.length) {
        setUserMore(false);
      } else {
        setUserLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
      setUserMore(hasMore);
    } catch (error) {
      console.error("Error fetching participant history:", error);
      // Set hasMore to false in case of error
      setUserMore(false);
    }
  };

  const loadMyInitialData = async () => {
    setMyJournals([]);

    try {
      const query = projectFirestore
        .collectionGroup("participantHistory")
        .where("userId", "==", profile?.uid)
        .where("feedbackReflectionStatus", "==", "submitted")
        .orderBy("createdAt", "desc")
        .limit(10);

      const snapshot = await query.get();

      const initialParticipantHistory: Participant[] = [];
      snapshot.forEach((doc) => {
        initialParticipantHistory.push(doc.data() as Participant);
      });

      setMyJournals(initialParticipantHistory);

      if (snapshot.docs.length <= 0) {
        setMore(false);
      } else {
        setMyLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching initial participant history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMyInitialData();
    // eslint-disable-next-line
  }, [profile]);

  return {
    journals,
    hasMore,
    myJournals,
    groupJournals,
    subGroupJournals,
    more,
    groupMore,
    subGroupMore,
    loading,
    selectedJournal,
    fetchParticipantHistoryJournal,
    fetchMyHistoryJournal,
    selectJournalById,
    unselectJournal,
    updateParticipantFeedback,
    fetchParticipantHistoryJournalByIds,
    fetchJournalByIds,
    userJournals,
    userMore,
    getAllJournalByUser,
    setUserJournals,
    setGroupJournals,
    setLastGroupDoc,
    initialGroupHistoryData,
  };
};
